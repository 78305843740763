import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { ChamadosService } from "src/app/services/chamados.service";
import { environment } from "src/environments/environment";
import { ClienteContatoService } from "src/app/services/cliente-contato.service";
import { DomSanitizer } from "@angular/platform-browser";
import { CategoriasService } from "src/app/services/categorias.service";
import { NavigationEnd, Router } from "@angular/router";
import swal from "sweetalert2";
import { ModalListaContatosClienteComponent } from "../modal-lista-contatos-cliente/modal-lista-contatos-cliente.component";
import { ModalMapaOperadorComponent } from "../modal-mapa-operador/modal-mapa-operador.component";
import { CamposCustomizaveisService } from "src/app/services/campos-customizaveis.service";
import { ModalInfosClienteChamadoComponent } from "../modal-infos-cliente-chamado/modal-infos-cliente-chamado.component";
import { TokenService } from "src/app/core/token/token.service";
import { ChatService } from "src/app/services/chat.service";
import { debounceTime, distinctUntilChanged, Subject, Subscription } from "rxjs";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { QuillConfigModel } from "src/app/models/quill/quillConfig.model";
import { QuillEditorComponent } from "ngx-quill";
import { QuillButtonOptionsModel } from "src/app/models/quill/quillButtonOptions.model";
import { ClienteEnderecoService } from "src/app/services/cliente-endereco.service";
import { ClienteService } from "src/app/services/cliente.service";
import { CategoryCatalogueService } from "src/app/services/category-catalogue.service";
declare let moment: any;

@Component({
  selector: "app-modal-abertura-ticket",
  templateUrl: "./modal-abertura-ticket.component.html",
  styleUrls: ["./modal-abertura-ticket.component.scss"],
})
export class ModalAberturaTicketComponent implements OnInit, OnDestroy {
  newTicketForm: UntypedFormGroup = new UntypedFormGroup({
    description: new UntypedFormControl("", Validators.required),
    accomplished: new UntypedFormControl("", Validators.required),
  });
  @Input() abrir_ticket;
  @Input() abrir_ticket_pai;
  @Input() chamado_origem_id;
  @Input() chatData;
  @Input() isPreview;
  hoje = moment().format("YYYY-MM-DDTHH:mm");
  chamado;
  chat;
  infosValidacaoCliente;
  data;
  filesSelected = [];
  filesSelectedCustom = [];
  uploadFileType: any = environment.getUploadConfig().docImg();
  uploadFileSize: any = environment.getUploadConfig().sizeMaxBytes;
  existe_bloqueio = false;
  existe_alerta = false;
  ultimosChamados;
  nao = this.translate.instant("NAO");
  sim = this.translate.instant("SIM");
  idContatoPadrao;
  rotaAtual;
  campos = [];
  camposCustomizaveis = [];
  camposCustomizaveisInicial = [];
  permissao = {
    EditarDataCriacao: this.tokenService.checkPermissions("@EditarDataCriacao"),
  };
  is_saving = false;
  selector: string;
  is_retencao: boolean;
  temPermissaoFeature: boolean;
  tabulacaoLista: any;
  newSubs: Subscription;
  quillConfigEditor: QuillConfigModel;
  editor: QuillEditorComponent;
  descricaoChamado = null;
  usuario_tecnico_id: any;
  business_unit;
  block_desk: boolean = false;
  default_desk: any;
  checkFieldSubject$ = new Subject<any>();
  campo_personalizado_preenchido_incorretamente: boolean = false;
  showFieldSituacaoContrato: boolean = false;
  required_default = {
    assunto: true,
    descricao: true
  }
  required_category = {
    primary: false,
    secondary: false,
  }
  categoriesReceived: any;
  enumBoolean = {
    '1': { id: '1', text: this.translate.instant('SIM'), value: true },
    '2': { id: '2', text: this.translate.instant('NAO'), value: false },
  }
  novoContatoAdd = false;
  preenchendoCampoCustom: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private chamadosService: ChamadosService,
    private clienteContatoService: ClienteContatoService,
    private _sanitizer: DomSanitizer,
    private categoriasService: CategoriasService,
    private router: Router,
    private modalService: NgbModal,
    private campoCustomizavelService: CamposCustomizaveisService,
    private tokenService: TokenService,
    private elemRef: ElementRef,
    private chatService: ChatService,
    private clienteEnderecoService: ClienteEnderecoService,
    private clienteService: ClienteService,
    private categoryCatalogueService: CategoryCatalogueService
  ) {
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
    this.getCamposVisiveis();
    this.checkFieldSubject$.pipe(
      debounceTime(1000)
    ).subscribe(value => {
      this.checkValue(value.valor, value.tipo_campo_id, value);
    });
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]["style"]["zoom"] = "0.85";

    this.rotaAtual = this.router.routerState.snapshot.url.replace(/[0-9]/g, "");
    this.setQuillEditorConfig();

    this.data = this.tokenService.convertDateTimezone(new Date());
    const all_infos = this.tokenService.getAllInfos() ? this.tokenService.getAllInfos() : null;
    if (all_infos && all_infos.empresa && all_infos.empresa.possui_acesso_novas_funcionalidades) {
      this.temPermissaoFeature =
        this.tokenService.getAllInfos()["empresa"][
        "possui_acesso_novas_funcionalidades"
        ];
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.newSubs = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });

    if (this.abrir_ticket) {
      this.chamado = {
        cliente_id: this.abrir_ticket.objCliente || this.abrir_ticket.cliente,
        data_criacao: null,
        chamado_origem_id: 3,
        dispositivo_vinculado: {
          id: this.abrir_ticket.dispositivo_id,
          text: this.abrir_ticket.dispositivo_origem,
        },
        categoria_primaria_id: null,
        categoria_secundaria_id: null,
        nome_contato: [],
        prioridade: null,
        tipo_chamado_id: null,
        setor_id: null,
        mesa_trabalho_id: null,
        seguir_automaticamente: false,
        usuario_tecnico_id: null,
        descricao: "",
        file: [],
        endereco_id: {}
      };
      if (this.abrir_ticket.alerta_usuario) {        
        this.chamado["alerta_usuario"] = this.abrir_ticket.alerta_usuario;
      }
      if (this.abrir_ticket.data_alerta) {
        this.data = new Date();
      }
      this.getInfos(this.abrir_ticket.objCliente || this.abrir_ticket.cliente);
      this.getUltimosTickets(
        this.abrir_ticket.objCliente || this.abrir_ticket.cliente
      );
      this.chamado.assunto = this.abrir_ticket.titulo;
      this.chamado.descricao =
        (this.abrir_ticket.data_alerta ? this.abrir_ticket.data_alerta : "") +
        ":<br>" +
        this.abrir_ticket.descricao;
    } else if (this.abrir_ticket_pai) {
      this.chamado = {
        cliente_id:
          this.abrir_ticket_pai.objCliente || this.abrir_ticket_pai.cliente,
        data_criacao: null,
        chamado_origem_id: 3,
        dispositivo_vinculado: {
          id: this.abrir_ticket_pai.dispositivo_id,
          text: this.abrir_ticket_pai.dispositivo_origem,
        },
        categoria_primaria_id: null,
        categoria_secundaria_id: null,
        nome_contato: [],
        prioridade: null,
        tipo_chamado_id: null,
        setor_id: null,
        mesa_trabalho_id: null,
        seguir_automaticamente: false,
        usuario_tecnico_id: null,
        descricao: "",
        file: [],
        endereco_id: {}
      };
      this.getInfos(
        this.abrir_ticket_pai.objCliente || this.abrir_ticket_pai.cliente
      );
      this.getUltimosTickets(
        this.abrir_ticket_pai.objCliente || this.abrir_ticket_pai.cliente
      );
      this.chamado.assunto = this.abrir_ticket_pai.titulo;
    } else if (this.chatData) {
      this.chat = {
        chat_id: this.chatData._id,
        realizado: "",
        motivo: "",
        atendimento: {
          is_comercial: true,
          is_externo: false,
          //total_horas: "",
          total_horas: this.diffTotalHoursChat(),
          data_criacao: this.chatData.data_atendimento_fila,
        },
      };
      this.chamado = {
        cliente_id: this.chatData.cliente && this.chatData.cliente.id ? this.chatData.cliente.id : null,
        data_criacao: null,
        chamado_origem_id: 3,
        dispositivo_vinculado: null,
        categoria_primaria_id: null,
        categoria_secundaria_id: null,
        nome_contato: null,
        mesa_trabalho_id: null,
        seguir_automaticamente: false,
        usuario_tecnico_id: null,
        prioridade: null,
        tipo_chamado_id: null,
        setor_id: null,
        descricao: this.chatData.descricao ? this.chatData.descricao : "",
        file: [],
        endereco_id: {},
        descricao_html: this.chatData.descricao_html
          ? this.chatData.descricao_html
          : "",
      };

      if (this.verificaChamadoVinculado(this.chatData)) {
        this.getTicketVinculado(this.chatData.chamado_vinculado);
      } else {
        if (this.chatData && this.chatData.cliente_id) {
          this.getClienteById(this.chamado.cliente_id);
        } else {
          this.idContatoPadrao = this.chatData.contato ? [this.chatData.contato] : null;
        }
      }

      this.loadTabulacao();
    } else {
      this.chamado = {
        cliente_id: null,
        data_criacao: null,
        chamado_origem_id: this.chamado_origem_id ? this.chamado_origem_id : 3,
        dispositivo_vinculado: null,
        categoria_primaria_id: null,
        categoria_secundaria_id: null,
        nome_contato: [],
        mesa_trabalho_id: null,
        seguir_automaticamente: false,
        usuario_tecnico_id: null,
        prioridade: null,
        tipo_chamado_id: null,
        setor_id: null,
        descricao: "",
        file: [],
        endereco_id: {}
      };
    }    
    this.newTicketForm.controls.description.setValue(this.chamado.descricao);
    this.usuario_tecnico_id = this.chamado.usuario_tecnico_id ? this.chamado.usuario_tecnico_id : null;
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  ngAfterContentInit() {
    this.infosValidacaoCliente = {
      limite_contrato: 0,
      is_retencao: false,
    };
    this.is_retencao = false;
  }

  trackByIndex(index): any {
    return index;
  }

  setQuillEditorConfig() {
    this.quillConfigEditor = {
      magicUrl: true,
      imageDrop: true,
      ImageResize: { modules: ["Resize", "DisplaySize", "Toolbar"] },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          [
            { align: [] },
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["code-block", "link", "image"],
          /* [{ 'font': ["poppins", "arial", "times-new-roman", "helvetica", "times", "courier-new", "verdana", "courier", "arial-narrow"] }],
          [{ 'color': [] }],  */
        ],
        handlers: { emoji: function () {} },
      },
      "toolbar-custom-button": new QuillButtonOptionsModel(),
      "emoji-toolbar": true,
      "emoji-textarea": false,
      "emoji-shortname": true,

      // mention: {
      //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      //   onSelect: (item, insertItem) => {
      //     const editor = this.editor.quillEditor;
      //     insertItem(item);
      //     editor.insertText(editor.getLength() - 1, "", "user");
      //   },
      //   source: (searchTerm, renderList) => {
      //     const values = [
      //       { id: 1, value: "Fredrik Sundqvist" },
      //       { id: 2, value: "Patrik Sjölin" },
      //     ];

      //     if (searchTerm.length === 0) {
      //       renderList(values, searchTerm);
      //     } else {
      //       const matches = [];

      //       values.forEach((entry) => {
      //         if (
      //           entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
      //           -1
      //         ) {
      //           matches.push(entry);
      //         }
      //       });
      //       renderList(matches, searchTerm);
      //     }
      //   },
      // },
      keyboard: {
        bindings: {
          // shiftEnter: {
          //   key: 13,
          //   shiftKey: true,
          //   handler: (range, context) => {
          //     // Handle shift+enter
          //     console.log("shift+enter")
          //   }
          // },
          enter: {
            key: 13,
            handler: (range, context) => {
              return true;
            },
          },
        },
      },
    };
  }

  checkImage(html: string): boolean {
    return html && html.match(/<img/) && html.match(/<img/).length > 0;
  }

  checkFields(formName: string): boolean {
    if (this.newTicketForm.get(formName).value) {
      let _cleanText = this.newTicketForm.get(formName).value && this.newTicketForm.get(formName).value.text ? this.newTicketForm.get(formName).value.text.replaceAll('/n', '') : this.newTicketForm.get(formName).value;
      let _html = this.newTicketForm.get(formName).value && this.newTicketForm.get(formName).value.html ? this.newTicketForm.get(formName).value.html : this.newTicketForm.get(formName).value;
      if((!_html || _cleanText.length <= 5) && !this.checkImage(_html)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  setValorFiltroListaPersonalizado(event, campoCustom) {
    campoCustom.valor = event;
  }

  verificaPai() {
    return this.abrir_ticket_pai;
  }

  verificaDdg(telefone: string) {
    return telefone && telefone.includes("0800");
  }

  checkOperador() {
    var _aux = false;
    if (this.verificaVisualizacao(10)) { // verifica a visibilidade do campo mesa de trabalho
      _aux = this.chamado.mesa_trabalho_id && this.chamado.mesa_trabalho_id.id ? true : false
    } else {
      _aux = true
    }
    return _aux
  }

  downloadAnexo(anexo) {
    var fileName = anexo.name;
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    var fileURL = anexo.url.changingThisBreaksApplicationSecurity;
    a.href = fileURL;
    a.target = "_blank";
    a.download = fileName;
    document.body.appendChild(a);

    setTimeout(() => {
      a.click();
      window.URL.revokeObjectURL(fileURL);
    });
  }

  getTicketVinculado(chamado) {
    this.newSubs = this.chamadosService.getChamadoById(chamado.id).subscribe(
      (result) => {        
        this.chamado = result.chamado;
        this.newTicketForm.controls.description.setValue(
          result.chamado.descricao
        );
        this.chamado.cliente_id = result.chamado.cliente
          ? result.chamado.cliente
          : null;
        this.chamado.assunto = result.chamado.assunto
          ? result.chamado.assunto
          : "";
        this.chamado.categoria_primaria_id = result.chamado.categoria_primaria
          ? result.chamado.categoria_primaria
          : this.chamado.categoria_primaria_id;
        this.chamado.categoria_secundaria_id = result.chamado
          .categoria_secundaria
          ? result.chamado.categoria_secundaria
          : this.chamado.categoria_secundaria_id;
        this.chamado.tipo_chamado_id = result.chamado.tipo_chamado
          ? result.chamado.tipo_chamado
          : this.chamado.tipo_chamado_id;
        this.chamado.mesa_trabalho_id = result.chamado.mesa_trabalho
          ? result.chamado.mesa_trabalho
          : this.chamado.mesa_trabalho_id;
        this.chamado.usuario_tecnico_id = { id: result.chamado.usuario_tecnico_id };
        this.chamado.setor_id = result.chamado.setor
          ? result.chamado.setor
          : result.chamada.setor_id;
        this.getInfos(this.chamado.cliente_id);
        this.getUltimosTickets(this.chamado.cliente_id);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  verificaChamadoVinculado(chamado) {
    return (
      chamado &&
      chamado.hasOwnProperty("chamado_vinculado") &&
      chamado.chamado_vinculado &&
      chamado.chamado_vinculado !== undefined &&
      chamado.chamado_vinculado !== null
    );
  }

  convertDateTimezoneToViewWithTZ(date): string {
    let _date = date;
    let zone = this.tokenService.getUsuario().timezone;
    _date.setHours(_date.getHours() - 3);
    let m = moment.tz(date, 'YYYY-MM-DD HH:mm:ss', zone);
    let dataFormatada = m.utc().format('YYYY-MM-DD HH:mm:ss');
    return dataFormatada;
  }

  diffTotalHoursChat() {
    var antes = moment(this.chatData.data_atendimento_fila)
    var depois = moment(
      moment(
        this.convertDateTimezoneToViewWithTZ(new Date())
      )
    )
    var minutos = depois.diff(antes, 'minutes');
    var horasFormatada = (Math.floor(minutos / 60)).toString().padStart(2, '0') + ':' + (minutos % 60).toString().padStart(2, '0')
    return `${horasFormatada}`
  }

  salvarEFinaliza(chamado, chat) {
    if (this.chatData) {
      if (!this.verificaChamadoVinculado(this.chatData)) {
        if (this.validaSalvar() === true) {
          return;
        }
        if (this.validaSalvarCamposCustomizaveis() === true) {
          return;
        }

        this.is_saving = true;
        var _chamado = Object.assign({}, chamado);

        //OBRIGATÓRIOS
        //formatando descricao
        // var _descricaoLimpa = new DOMParser().parseFromString(
        //   _chamado.descricao,
        //   "text/html"
        // );
        // _chamado["descricao_html"] = _chamado.descricao;
        // // var _descricaoLimpa = new DOMParser().parseFromString(
        // //   this.newTicketForm.controls.description.value,
        // //   "text/html"
        // // );
        // _chamado["descricao_html"] = _chamado.descricao;
        // _chamado.descricao = _descricaoLimpa.body.textContent;
        _chamado.descricao = this.newTicketForm.get('description').value.text;
        _chamado.descricao_html = this.newTicketForm.get('description').value.html;
        _chamado.cliente_id =
          _chamado.cliente_id && _chamado.cliente_id.id
            ? _chamado.cliente_id.id
            : null;
        _chamado.dispositivo_vinculado =
          _chamado.dispositivo_vinculado && _chamado.dispositivo_vinculado.id
            ? _chamado.dispositivo_vinculado
            : null;
        _chamado.dispositivo_vinculado_id =
          _chamado.dispositivo_vinculado !== null
            ? _chamado.dispositivo_vinculado.id
            : null;
        _chamado.categoria_primaria_id =
          _chamado.categoria_primaria_id && _chamado.categoria_primaria_id.id
            ? _chamado.categoria_primaria_id.id
            : _chamado.categoria_primaria_id ? _chamado.categoria_primaria_id : null;
        _chamado.mesa_trabalho_id =
          _chamado.mesa_trabalho_id && _chamado.mesa_trabalho_id.id
            ? _chamado.mesa_trabalho_id.id
            : null;
        _chamado.tipo_chamado_id =
          _chamado.tipo_chamado_id && _chamado.tipo_chamado_id.id
            ? _chamado.tipo_chamado_id.id
            : null;
        _chamado.usuario_tecnico_id =
          _chamado.usuario_tecnico_id && _chamado.usuario_tecnico_id.id
            ? _chamado.usuario_tecnico_id.id
            : null;

        //OPCIONAIS
        if (this.abrir_ticket_pai) {
          _chamado["chamado_pai_id"] = this.abrir_ticket_pai.id;
        }
        if (_chamado.data_criacao) {
          _chamado.data_criacao = this.tokenService.convertDateTimezoneStockFormat(new Date(_chamado.data_criacao));
        }
        if (_chamado.categoria_secundaria_id) {
          _chamado.categoria_secundaria_id =
            _chamado.categoria_secundaria_id.id
              ? _chamado.categoria_secundaria_id.id
              : _chamado.categoria_secundaria_id;
        }
        if (_chamado.prioridade) {
          _chamado.prioridade = _chamado.prioridade.id;
        }
        if (_chamado.setor_id) {
          _chamado.setor_id = _chamado.setor_id.id;
        }
        if (_chamado.nome_contato) {
          var contatos = "";
          _chamado.nome_contato.forEach((contato, index) => {
            if (_chamado.nome_contato.length > 1) {
              if (index !== _chamado.nome_contato.length) {
                contatos += contato + ",";
              } else {
                contatos += contato;
              }
            } else {
              contatos += contato;
            }
          });

          _chamado.nome_contato = contatos;
        }
        if (_chamado.email_conferencia) {
          if (typeof _chamado.email_conferencia === "object") {
            var emails_contatos = "";
            _chamado.email_conferencia.forEach((email, index) => {
              if (_chamado.email_conferencia.length > 1) {
                if (index !== _chamado.email_conferencia.length) {
                  emails_contatos += email + ",";
                } else {
                  emails_contatos += email;
                }
              } else {
                emails_contatos += email;
              }
            });

            _chamado.email_conferencia = emails_contatos;
          }
        }

        if (_chamado.endereco_id) {
          _chamado.endereco_id = _chamado.endereco_id.id;
        }

        //CUSTOMIZAVEIS        
        this.adjustmentCustomFieldsList(_chamado);

        if (_chamado.file.length === 0) {
          chat.motivo = chat.motivo;
          chat.realizado = this.newTicketForm.value.accomplished.text ? this.newTicketForm.value.accomplished.text : this.newTicketForm.value.accomplished;
          var payload = {
            chamado: _chamado,
            chat: chat,
          };

          this.newSubs = this.chatService
            .finalizarChatCriaTicket(payload)
            .subscribe(
              (result) => {
                if (
                  this.idContatoPadrao !== undefined &&
                  this.idContatoPadrao.length > 0
                ) {
                  var adicionaContatos = {
                    chamado_id: result.chamado.id,
                    contatos: [],
                  };

                  this.idContatoPadrao.forEach((item) => {
                    adicionaContatos.contatos.push(item.id);
                  });

                  this.newSubs = this.chamadosService
                    .adicionarContatoChamado(adicionaContatos)
                    .subscribe(
                      (results) => {
                        this.is_saving = false;
                        result["acao"] = "finalizar";
                        payload["chat"] = this.chatData;
                        this.modal.close(result);
                      },
                      (error) => {
                        this.toastr.show(
                          error,
                          "",
                          environment.toastrConfig("danger")
                        );
                      }
                    );
                } else {
                  this.is_saving = false;
                  result["acao"] = "finalizar";
                  payload["chat"] = this.chatData;
                  this.modal.close(result);
                }
              },
              (error) => {
                this.toastr.show(error, "", environment.toastrConfig("danger"));
                this.is_saving = false;
              }
            );
        } else {
          var formData = new FormData();
          _chamado.file.forEach((file) => {
            formData.append("file", file, file.name);
          });
          chat.motivo = chat.motivo.toString();
          chat.realizado = this.newTicketForm.value.accomplished.text ? this.newTicketForm.value.accomplished.text : this.newTicketForm.value.accomplished;
          var payload = {
            chamado: _chamado,
            chat: chat,
          };

          this.newSubs = this.chatService
            .finalizarChatCriaTicket(payload)
            .subscribe(
              (result) => {
                if (
                  this.idContatoPadrao !== undefined &&
                  this.idContatoPadrao.length > 0
                ) {
                  var adicionaContatos = {
                    chamado_id: result.chamado.id,
                    contatos: [],
                  };

                  this.idContatoPadrao.forEach((item) => {
                    adicionaContatos.contatos.push(item.id);
                  });

                  this.newSubs = this.chamadosService
                    .adicionarContatoChamado(adicionaContatos)
                    .subscribe(
                      (results) => {
                        this.toastr.show(
                          this.translate.instant("SALVO_SUCESSO"),
                          "",
                          environment.toastrConfig("success")
                        );
                      },
                      (error) => {
                        this.toastr.show(
                          error,
                          "",
                          environment.toastrConfig("danger")
                        );
                      }
                    );
                }

                formData.append("chamado_id", result.chamado.id);
                this.newSubs = this.chamadosService
                  .uploadAnexoChamado(formData)
                  .subscribe(
                    (resultAnexo) => {
                      if (this.infosValidacaoCliente.is_retencao) {
                        this.toastr.show(
                          this.translate.instant("SALVO_RETENCAO"),
                          "",
                          environment.toastrConfig("success")
                        );
                      } else {
                        this.toastr.show(
                          this.translate.instant("SALVO_SUCESSO"),
                          "",
                          environment.toastrConfig("success")
                        );
                      }
                      result["acao"] = "finalizar";
                      payload["chat"] = this.chatData;
                      this.modal.close(result);
                    },
                    (error) => {
                      this.toastr.show(
                        error,
                        "",
                        environment.toastrConfig("danger")
                      );
                      this.is_saving = false;
                    }
                  );
              },
              (error) => {
                this.toastr.show(error, "", environment.toastrConfig("danger"));
                this.is_saving = false;
              }
            );
        }
      } else {
        chat.motivo = chat.motivo;
        chat.realizado = this.newTicketForm.value.accomplished.text ? this.newTicketForm.value.accomplished.text : this.newTicketForm.value.accomplished;
        var payload = {
          chamado: chamado,
          chat: chat,
        };
        payload.chamado.usuario_tecnico_id =
          chamado.usuario_tecnico_id && chamado.usuario_tecnico_id.id
            ? chamado.usuario_tecnico_id.id
            : null

        payload.chamado.categoria_primaria_id =
          chamado.categoria_primaria && chamado.categoria_primaria.id
            ? chamado.categoria_primaria.id
            /* : payload.chamado.categoria_primaria_id
            ? payload.chamado.categoria_primaria_id */
            : null;
        payload.chamado.categoria_secundaria_id =
          chamado.categoria_secundaria && chamado.categoria_secundaria.id
            ? chamado.categoria_secundaria.id
            /* : payload.chamado.categoria_secundaria_id
            ? payload.chamado.categoria_secundaria_id */
            : null;
        payload.chamado.contato_id =
          chamado.contato && chamado.contato.id
            ? chamado.contato.id
            /* : payload.chamado.contato_id
            ? payload.chamado.contato_id */
            : null;
        payload.chamado.contrato_parcela_id =
          chamado.contrato_parcela && chamado.contrato_parcela.id
            ? chamado.contrato_parcela.id
            /* : payload.chamado.contrato_parcela_id
            ? payload.chamado.contrato_parcela_id */
            : null;
        payload.chamado.setor_id =
          chamado.setor && chamado.setor.id ? chamado.setor.id : null;
        payload.chamado.tipo_chamado_id =
          chamado.tipo_chamado && chamado.tipo_chamado.id
            ? chamado.tipo_chamado.id
            /* : payload.chamado.tipo_chamado_id
            ? payload.chamado.tipo_chamado_id */
            : null;
        payload.chamado.cliente_id =
          chamado.cliente_id && chamado.cliente_id.id
            ? chamado.cliente.id
            /* : payload.chamado.cliente_id
            ? payload.chamado.cliente_id */
            : null;
        payload.chamado.mesa_trabalho_id =
          chamado.mesa_trabalho_id && chamado.mesa_trabalho_id.id
            ? chamado.mesa_trabalho_id.id
            /* : payload.chamado.mesa_trabalho_id
            ? payload.chamado.mesa_trabalho_id */
            : null;
        payload.chamado.endereco_id =
          chamado.endereco_id && chamado.endereco_id.id
            ? chamado.endereco_id.id
            : payload.chamado.endereco_id
              ? payload.chamado.endereco_id
              : null;
        delete payload.chamado.cliente;
        delete payload.chamado.moeda;

        var _chamado = Object.assign({}, chamado);

        payload.chamado.descricao = this.newTicketForm.get('description').value.text;
        payload.chamado.descricao_html = this.newTicketForm.get('description').value.html;
        payload.chamado.email_conferencia = payload.chamado.email_conferencia ? payload.chamado.email_conferencia.toString() : ''
        payload.chamado.nome_contato = payload.chamado.nome_contato ? payload.chamado.nome_contato.toString() : ''

        this.finalizarChatEncerrarTicketTecnico(payload);
      }
    }
  }

  finalizarChatEncerrarTicketTecnico(payload) {
    this.newSubs = this.chatService
      .finalizarChatEncerrarTicketTecnico(payload)
      .subscribe(
        (result) => {
          this.toastr.show(
            this.translate.instant("SALVO_SUCESSO"),
            "",
            environment.toastrConfig("success")
          );
          result["acao"] = "finalizar";
          this.modal.close(result);
        },
        (error) => {
          this.modal.close();
          console.error(error);
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        }
      );
  }

  finalizarChatCriaTicket(payload) {
    this.newSubs = this.chatService.finalizarChatCriaTicket(payload).subscribe(
      (result) => {
        this.toastr.show(
          this.translate.instant("SALVO_SUCESSO"),
          "",
          environment.toastrConfig("success")
        );
        this.modal.close(result);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  salvarChamado(chamado): void {
    if (this.validaSalvar() === true) {
      return;
    }
    if (this.validaSalvarCamposCustomizaveis() === true) {
      return;
    }

    this.is_saving = true;
    var _chamado = Object.assign({}, chamado);

    //OBRIGATÓRIOS
    //formatando descricao
    // var _descricaoLimpa = new DOMParser().parseFromString(_chamado.descricao, 'text/html');
    // _chamado['descricao_html'] = _chamado.descricao;
    // // var _descricaoLimpa = new DOMParser().parseFromString(
    // //   this.newTicketForm.controls.description.value,
    // //   "text/html"
    // // );
    // _chamado["descricao_html"] = _chamado.descricao;
    // _chamado.descricao = _descricaoLimpa.body.textContent;
    _chamado.descricao = this.newTicketForm.get('description').value.text;
    _chamado.descricao_html = this.newTicketForm.get('description').value.html;
    _chamado.cliente_id =
      _chamado.cliente_id && _chamado.cliente_id.id
        ? _chamado.cliente_id.id
        : null;
    _chamado.dispositivo_vinculado =
      _chamado.dispositivo_vinculado && _chamado.dispositivo_vinculado.id
        ? _chamado.dispositivo_vinculado
        : null;
    _chamado.dispositivo_vinculado_id =
      _chamado.dispositivo_vinculado !== null
        ? _chamado.dispositivo_vinculado.id
        : null;
    _chamado.mesa_trabalho_id =
      _chamado.mesa_trabalho_id && _chamado.mesa_trabalho_id.id
        ? _chamado.mesa_trabalho_id.id
        : null;
    _chamado.tipo_chamado_id =
      _chamado.tipo_chamado_id && _chamado.tipo_chamado_id.id
        ? _chamado.tipo_chamado_id.id
        : null;
    _chamado.usuario_tecnico_id =
      _chamado.usuario_tecnico_id && _chamado.usuario_tecnico_id.id
        ? _chamado.usuario_tecnico_id.id
        : null;

    //OPCIONAIS
    if (this.abrir_ticket_pai) {
      _chamado["chamado_pai_id"] = this.abrir_ticket_pai.id;
    }
    if (_chamado.data_criacao) {
      _chamado.data_criacao = this.tokenService.convertDateTimezoneStockFormat(new Date(_chamado.data_criacao));
    }
    if (_chamado.prioridade) {
      _chamado.prioridade = _chamado.prioridade.id;
    }
    if (_chamado.setor_id) {
      _chamado.setor_id = _chamado.setor_id.id;
    }
    if (_chamado.nome_contato) {
      var contatos = "";
      _chamado.nome_contato.forEach((contato, index) => {
        if (_chamado.nome_contato.length > 1) {
          if (index !== _chamado.nome_contato.length) {
            contatos += contato + ",";
          } else {
            contatos += contato;
          }
        } else {
          contatos += contato;
        }
      });

      _chamado.nome_contato = contatos;
    }
    if (_chamado.email_conferencia) {
      if (typeof _chamado.email_conferencia === "object") {
        var emails_contatos = "";
        _chamado.email_conferencia.forEach((email, index) => {
          if (_chamado.email_conferencia.length > 1) {
            if (index !== _chamado.email_conferencia.length) {
              emails_contatos += email + ",";
            } else {
              emails_contatos += email;
            }
          } else {
            emails_contatos += email;
          }
        });

        _chamado.email_conferencia = emails_contatos;
      }
    }
    if (_chamado.endereco_id) {
      _chamado.endereco_id = _chamado.endereco_id.id;
    }

    //CUSTOMIZAVEIS    
    this.adjustmentCustomFieldsList(_chamado);


    if (_chamado.file.length === 0) {
      this.newSubs = this.chamadosService.criarChamado(_chamado).subscribe(
        (result) => {
          if (
            this.idContatoPadrao !== undefined &&
            this.idContatoPadrao.length > 0
          ) {
            var adicionaContatos = {
              chamado_id: result.chamado.id,
              contatos: [],
            };

            this.idContatoPadrao.forEach((item) => {
              adicionaContatos.contatos.push(item.id);
            });
            this.newSubs = this.chamadosService
              .adicionarContatoChamado(adicionaContatos)
              .subscribe(
                (results) => {
                  if (this.infosValidacaoCliente.is_retencao) {
                    this.toastr.show(
                      this.translate.instant("SALVO_RETENCAO") +
                      " Ticket: #" +
                      result.chamado.codigo,
                      "",
                      environment.toastrConfig("success")
                    );
                    this.is_saving = false;
                  } else {
                    this.toastr.show(
                      this.translate.instant("SALVO_SUCESSO") +
                      " Ticket: #" +
                      result.chamado.codigo,
                      "",
                      environment.toastrConfig("success")
                    );
                    this.is_saving = false;
                  }

                  result["acao"] = "associar";
                  //this.chatData.chamado_id = result.chamado.id
                  result["chat"] = this.chatData;
                  if (this.chamado_origem_id === 16) {
                    result["fechar"] = false;
                  }
                  this.modal.close(result);
                },
                (error) => {
                  this.toastr.show(
                    error,
                    "",
                    environment.toastrConfig("danger")
                  );
                }
              );
          } else {
            if (this.infosValidacaoCliente.is_retencao) {
              this.toastr.show(
                this.translate.instant("SALVO_RETENCAO") +
                " Ticket: #" +
                result.chamado.codigo,
                "",
                environment.toastrConfig("success")
              );
              this.is_saving = false;
            } else {
              this.toastr.show(
                this.translate.instant("SALVO_SUCESSO") +
                " Ticket: #" +
                result.chamado.codigo,
                "",
                environment.toastrConfig("success")
              );
              this.is_saving = false;
            }
            result["acao"] = "associar";
            //this.chatData.chamado_id = result.chamado.id
            result["chat"] = this.chatData;
            if (this.chamado_origem_id === 16) {
              result["fechar"] = false;
            }
            this.modal.close(result);
          }

          if (this.chatData && this.chatData._id) {
            let data = {
              chamado_id: result.chamado.id,
            };

            this.chatData.chamado_id = result.chamado.id;

            this.newSubs = this.chatService
              .vincularChamado(this.chatData._id, data)
              .subscribe(
                (result) => {
                  this.toastr.show(
                    this.translate.instant("ASSOCIADO_SUCESSO"),
                    "",
                    environment.toastrConfig("success")
                  );
                },
                (error) => {
                  this.toastr.show(
                    error,
                    "",
                    environment.toastrConfig("danger")
                  );
                }
              );
          }
        },
        (error) => {
          this.toastr.show(error, "", environment.toastrConfig("danger"));
          this.is_saving = false;
        }
      );
    } else {
      var formData = new FormData();
      _chamado.file.forEach((file) => {
        formData.append("file", file, file.name);
      });

      this.newSubs = this.chamadosService.criarChamado(_chamado).subscribe(
        (result) => {
          if (
            this.idContatoPadrao !== undefined &&
            this.idContatoPadrao.length > 0
          ) {
            var adicionaContatos = {
              chamado_id: result.chamado.id,
              contatos: [],
            };

            this.idContatoPadrao.forEach((item) => {
              adicionaContatos.contatos.push(item.id);
            });

            this.newSubs = this.chamadosService
              .adicionarContatoChamado(adicionaContatos)
              .subscribe(
                (results) => { },
                (error) => {
                  this.toastr.show(
                    error,
                    "",
                    environment.toastrConfig("danger")
                  );
                }
              );
          }

          if (this.chatData && this.chatData._id) {
            let data = {
              chamado_id: result.chamado.id,
            };

            this.chatData.chamado_id = result.chamado.id;

            this.newSubs = this.chatService
              .vincularChamado(this.chatData._id, data)
              .subscribe(
                (result) => {
                  this.toastr.show(
                    this.translate.instant("ASSOCIADO_SUCESSO"),
                    "",
                    environment.toastrConfig("success")
                  );
                },
                (error) => {
                  this.toastr.show(
                    error,
                    "",
                    environment.toastrConfig("danger")
                  );
                }
              );
          }
          formData.append("chamado_id", result.chamado.id);

          this.newSubs = this.chamadosService
            .uploadAnexoChamado(formData)
            .subscribe(
              (resultAnexo) => {
                if (this.infosValidacaoCliente.is_retencao) {
                  this.toastr.show(
                    this.translate.instant("SALVO_RETENCAO"),
                    "",
                    environment.toastrConfig("success")
                  );
                } else {
                  this.toastr.show(
                    this.translate.instant("SALVO_SUCESSO"),
                    "",
                    environment.toastrConfig("success")
                  );
                }
                result["acao"] = "associar";
                result["chat"] = this.chatData;
                this.modal.close(result);
              },
              (error) => {
                this.toastr.show(error, "", environment.toastrConfig("danger"));
                this.is_saving = false;
              }
            );
        },
        (error) => {
          this.toastr.show(error, "", environment.toastrConfig("danger"));
          this.is_saving = false;
        }
      );
    }
  }

  salvarEAbrir(chamado): void {
    if (this.validaSalvar() === true) {
      return;
    }
    if (this.validaSalvarCamposCustomizaveis() === true) {
      return;
    }

    this.is_saving = true;
    var _chamado = Object.assign({}, chamado);

    //OBRIGATÓRIOS
    //formatando descricao
    // var _descricaoLimpa = new DOMParser().parseFromString(
    //   this.newTicketForm.controls.description.value,
    //   "text/html"
    // );
    // _chamado["descricao_html"] = _chamado.descricao;
    // _chamado.descricao = _descricaoLimpa.body.textContent;
    _chamado.descricao = this.newTicketForm.get('description').value.text;
    _chamado.descricao_html = this.newTicketForm.get('description').value.html;

    _chamado.cliente_id =
      _chamado.cliente_id && _chamado.cliente_id.id
        ? _chamado.cliente_id.id : _chamado.cliente_id

    _chamado.dispositivo_vinculado =
      _chamado.dispositivo_vinculado && _chamado.dispositivo_vinculado.id !== null
        ? _chamado.dispositivo_vinculado : null;

    _chamado.dispositivo_vinculado_id =
      _chamado.dispositivo_vinculado && _chamado.dispositivo_vinculado.id && _chamado.dispositivo_vinculado.id !== null
        ? _chamado.dispositivo_vinculado.id : null;

    // _chamado.categoria_primaria_id = 
    //   _chamado.categoria_primaria_id && _chamado.categoria_primaria_id.id 
    //     ? _chamado.categoria_primaria_id.id : _chamado.categoria_primaria_id;

    _chamado.mesa_trabalho_id =
      _chamado.mesa_trabalho_id && _chamado.mesa_trabalho_id.id
        ? _chamado.mesa_trabalho_id.id : _chamado.mesa_trabalho_id;

    _chamado.tipo_chamado_id =
      _chamado.tipo_chamado_id && _chamado.tipo_chamado_id.id
        ? _chamado.tipo_chamado_id.id : _chamado.tipo_chamado_id;

    _chamado.usuario_tecnico_id =
      _chamado.usuario_tecnico_id && _chamado.usuario_tecnico_id.id
        ? _chamado.usuario_tecnico_id.id : _chamado.usuario_tecnico_id;

    //OPCIONAIS
    if (this.abrir_ticket_pai) {
      _chamado["chamado_pai_id"] = this.abrir_ticket_pai.id;
    }
    if (_chamado.data_criacao) {
      _chamado.data_criacao = this.tokenService.convertDateTimezoneStockFormat(new Date(_chamado.data_criacao));
    }
    if (_chamado.prioridade) {
      _chamado.prioridade = _chamado.prioridade.id;
    }
    if (_chamado.setor_id) {
      _chamado.setor_id = _chamado.setor_id.id;
    }
    if (_chamado.nome_contato) {
      var contatos = "";
      _chamado.nome_contato.forEach((contato, index) => {
        if (_chamado.nome_contato.length > 1) {
          if (index !== _chamado.nome_contato.length) {
            contatos += contato + ",";
          } else {
            contatos += contato;
          }
        } else {
          contatos += contato;
        }
      });

      _chamado.nome_contato = contatos;
    }
    if (_chamado.email_conferencia) {
      if (typeof _chamado.email_conferencia === "object") {
        var emails_contatos = "";
        _chamado.email_conferencia.forEach((email, index) => {
          if (_chamado.email_conferencia.length > 1) {
            if (index !== _chamado.email_conferencia.length) {
              emails_contatos += email + ",";
            } else {
              emails_contatos += email;
            }
          } else {
            emails_contatos += email;
          }
        });

        _chamado.email_conferencia = emails_contatos;
      }
    }
    if (_chamado.endereco_id) {
      _chamado.endereco_id = _chamado.endereco_id.id;
    }

    //CUSTOMIZAVEIS
    this.adjustmentCustomFieldsList(_chamado);

    if (_chamado.file.length === 0) {
      this.newSubs = this.chamadosService.criarChamado(_chamado).subscribe(
        (result) => {
          if (
            this.idContatoPadrao !== undefined &&
            this.idContatoPadrao.length > 0
          ) {
            var adicionaContatos = {
              chamado_id: result.chamado.id,
              contatos: [],
            };

            this.idContatoPadrao.forEach((item) => {
              adicionaContatos.contatos.push(item.id);
            });

            this.newSubs = this.chamadosService
              .adicionarContatoChamado(adicionaContatos)
              .subscribe(
                (results) => {
                  if (this.infosValidacaoCliente.is_retencao) {
                    this.toastr.show(
                      this.translate.instant("SALVO_RETENCAO") +
                      " Ticket: #" +
                      result.chamado.codigo,
                      "",
                      environment.toastrConfig("success")
                    );
                    this.is_saving = false;
                  } else {
                    this.toastr.show(
                      this.translate.instant("SALVO_SUCESSO") +
                      " Ticket: #" +
                      result.chamado.codigo,
                      "",
                      environment.toastrConfig("success")
                    );
                    this.is_saving = false;
                  }
                  if (this.chamado_origem_id === 16) {
                    result["fechar"] = true;
                  }
                  this.modal.close(result);
                  if (!this.chamado_origem_id) {
                    this.router.navigate([
                      "/tickets/details/",
                      result.chamado.id,
                    ]);
                  }
                },
                (error) => {
                  this.toastr.show(
                    error,
                    "",
                    environment.toastrConfig("danger")
                  );
                }
              );
          } else {
            if (this.infosValidacaoCliente.is_retencao) {
              this.toastr.show(
                this.translate.instant("SALVO_RETENCAO") +
                " Ticket: #" +
                result.chamado.codigo,
                "",
                environment.toastrConfig("success")
              );
              this.is_saving = false;
            } else {
              this.toastr.show(
                this.translate.instant("SALVO_SUCESSO") +
                " Ticket: #" +
                result.chamado.codigo,
                "",
                environment.toastrConfig("success")
              );
              this.is_saving = false;
            }
            this.modal.close(result);
            if (!this.chamado_origem_id) {
              this.router.navigate(["/tickets/details/", result.chamado.id]);
            }
          }
        },
        (error) => {
          this.toastr.show(error, "", environment.toastrConfig("danger"));
          this.is_saving = false;
        }
      );
    } else {
      var formData = new FormData();
      _chamado.file.forEach((file) => {
        formData.append("file", file, file.name);
      });

      this.newSubs = this.chamadosService.criarChamado(_chamado).subscribe(
        (result) => {
          if (
            this.idContatoPadrao !== undefined &&
            this.idContatoPadrao.length > 0
          ) {
            var adicionaContatos = {
              chamado_id: result.chamado.id,
              contatos: [],
            };

            this.idContatoPadrao.forEach((item) => {
              adicionaContatos.contatos.push(item.id);
            });

            this.newSubs = this.chamadosService
              .adicionarContatoChamado(adicionaContatos)
              .subscribe(
                (results) => { },
                (error) => {
                  this.toastr.show(
                    error,
                    "",
                    environment.toastrConfig("danger")
                  );
                }
              );
          }
          formData.append("chamado_id", result.chamado.id);
          this.newSubs = this.chamadosService
            .uploadAnexoChamado(formData)
            .subscribe(
              (resultAnexo) => {
                this.toastr.show(
                  this.translate.instant("SALVO_SUCESSO") +
                  " Ticket: #" +
                  result.chamado.codigo,
                  "",
                  environment.toastrConfig("success")
                );
                if (this.chamado_origem_id === 16) {
                  result["fechar"] = true;
                }
                this.modal.close(result);
                if (!this.chamado_origem_id) {
                  this.router.navigate([
                    "/tickets/details/",
                    result.chamado.id,
                  ]);
                }
              },
              (error) => {
                this.toastr.show(error, "", environment.toastrConfig("danger"));
                this.is_saving = false;
              }
            );
        },
        (error) => {
          this.toastr.show(error, "", environment.toastrConfig("danger"));
          this.is_saving = false;
        }
      );
    }
  }

  loadTabulacao() {
    this.newSubs = this.chatService
      .getTabulacao(this.chatData.widget.id, false)
      .subscribe((res) => {
        this.tabulacaoLista = res.dropDown;
      });
  }

  onFileDropped(event) {
    this.changeFile(event);
  }

  onFileDroppedCustom(event) {
    this.changeFileCustom(event);
  }

  labelsCamposSistema = {
    nome_cliente: '',
    assunto_chamado: '',
    descricao: '',
    chamado_anexo: '',
  }

  setLabelsCamposSistema(campo): void {
    if (campo.chave === '@NomeCliente') {
      this.labelsCamposSistema.nome_cliente = campo.label;
    }

    if (campo.chave === '@AssuntoChamado') {
      this.labelsCamposSistema.assunto_chamado = campo.label;
    }

    if (campo.chave === '@Descricao') {
      this.labelsCamposSistema.descricao = campo.label;
    }

    if (campo.chave === '@ChamadoAnexo') {
      this.labelsCamposSistema.chamado_anexo = campo.label;
    }
  }

  getCamposVisiveis(): void {
    this.newSubs = this.campoCustomizavelService
      .buscarCamposPortalTecnicoPadrao()
      .subscribe(
        {
          next: (results) => {
            this.camposCustomizaveis = [];
            results.map((campo) => {
              this.setLabelsCamposSistema(campo);
              if (campo.chave === '@SituacaoContrato') {
                this.showFieldSituacaoContrato = campo.is_visivel;
              }
              if (campo.chave === '@Categorias' || campo.chave === '@CategoriaPrimaria') this.required_category.primary = campo.is_obrigatorio;
              if (campo.chave === '@SubCategoria' || campo.chave === '@CategoriaSecundaria') this.required_category.secondary = campo.is_obrigatorio;
              if (campo.chave === '@AssuntoChamado') this.required_default.assunto = campo.is_obrigatorio;
              if (campo.chave === '@Descricao') this.required_default.descricao = campo.is_obrigatorio;
              if (campo.ordem > 5) {
                let aux = {
                  id: campo.id,
                  tipo_campo_id: campo.tipo_campo_id,
                  label: campo.label,
                  is_visivel: campo.is_visivel,
                  is_obrigatorio: campo.is_obrigatorio,
                  valor: campo.valor_padrao,
                  is_check: true,
                  chave: campo.chave,
                  ordem: campo.ordem,
                  atributo: campo.atributo
                }

                if (campo.tipo_campo_id === 3 && campo.chave === '@DataCriacao') {
                  this.chamado.data_criacao = this.tokenService.convertDateTimezoneStockFormat(new Date());
                  aux.valor = this.tokenService.convertDateTimezoneStockFormat(new Date());
                }

                if (!campo.chave && (!campo.tipo_campo_id || campo.tipo_campo_id === 7) && campo.is_visivel) {
                  aux.valor = this.formatValuesToArray(campo.valor_padrao);
                }

                this.camposCustomizaveis.push(aux);
                this.camposCustomizaveisInicial.push(aux);
              }

            });

            this.camposCustomizaveis = this.camposCustomizaveis.sort((a, b) => {
              return a.ordem - b.ordem;
            });


            this.camposCustomizaveisInicial = this.camposCustomizaveisInicial.sort((a, b) => {
              return a.ordem - b.ordem;
            });


            this.validacaoGatilho();
          }, error: (error) => {
            this.toastr.show(error, "", environment.toastrConfig("danger"));
          }
        }
      );
  }

  formatData(event): any {
    if (event) {
      return moment(event).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return event;
    }
  }

  getCamposCustomizaveis(): void {
    this.newSubs = this.campoCustomizavelService
      .buscarCamposCustomizaveisEmpresa()
      .subscribe(
        (results) => {
          results.campos.forEach((campo) => {
            if (campo.chave === '@SituacaoContrato') {
              this.showFieldSituacaoContrato = campo.is_visivel;
            }
            this.camposCustomizaveis.push({
              id: campo.id,
              tipo_campo_id: campo.tipo_campo_id,
              label: campo.label,
              is_visivel: campo.is_visivel,
              is_obrigatorio: campo.is_obrigatorio,
              valor: campo.valor_padrao,
              is_check: true,
              chave: campo.chave
            });
          });
        },
        (error) => {
          this.toastr.show(error, "", environment.toastrConfig("danger"));
        }
      );
  }

  verificaVisualizacao(id): boolean {
    var visualiza;
    this.campos.forEach((campo) => {
      if (campo.id === id) {
        visualiza = campo.possui_visualizacao;
      }
    });

    return visualiza;
  }

  onPaste(e) {
    return e.clipboardData.getData("Text") !== "";
  }

  getCliente(event) {
    this.chamado.cliente_id = event;
  }

  verificaCamposFinalizarChat() {
    return (
      this.isEmpty(this.chamado.cliente_id) ||
      this.chamado.cliente_id === null ||
      this.chamado.cliente_id === undefined ||
      (this.required_default.assunto && !this.chamado.assunto) ||
      (this.verifyDescricao()) ||
      (this.verifyAccomplished()) ||
      this.isEmpty(this.chamado.cliente_id) ||
      this.chat.motivo === null ||
      this.chat.motivo === undefined ||
      this.chat.motivo === "" ||
      this.verificaTodosRequerido()
    );
  }

  getMotivo(event) {
    this.chat.motivo = event ? event.toString() : null;
  }

  verificaCampos() {
    return (
      this.isEmpty(this.chamado.cliente_id) ||
      this.chamado.cliente_id === null ||
      this.chamado.cliente_id === undefined ||
      (this.required_default.assunto && !this.chamado.assunto) ||
      (this.verifyDescricao()) ||
      this.chamado.data_criacao === undefined ||
      this.chamado.data_criacao === null ||
      this.chamado.data_criacao === "" ||
      this.verificaTodosRequerido() ||
      this.verificaCampoPersonalizadoPreenchido()
    );
  }

  verificaCampoPersonalizadoPreenchido(): boolean {
    return this.campo_personalizado_preenchido_incorretamente;
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  verificaTodosRequerido() {
    var desabilitado = [];
    this.campos.forEach((campo) => {
      if (campo.possui_visualizacao === true && campo.is_obrigatorio === true) {
        switch (campo.id) {
          case 9:
            if (!this.chamado.categoria_primaria_id) {
              desabilitado.push(campo);
            }
            break;
          case 5:
            if (this.isEmpty(this.chamado.prioridade)) {
              desabilitado.push(campo);
            }
            break;
          case 6:
            if (this.isEmpty(this.chamado.dispositivo_vinculado)) {
              desabilitado.push(campo);
            }
            break;
          case 7:
            if (!this.idContatoPadrao || this.idContatoPadrao.length === 0) {
              desabilitado.push(campo);
            }
            break;
          case 8:
            if (this.isEmpty(this.chamado.tipo_chamado_id)) {
              desabilitado.push(campo);
            }
            break;
          case 10:
            if (this.isEmpty(this.chamado.mesa_trabalho_id)) {
              desabilitado.push(campo);
            }
            break;
          case 11:
            if (this.isEmpty(this.chamado.usuario_tecnico_id)) {
              desabilitado.push(campo);
            }
            break;
          case 12:
            if (this.isEmpty(this.chamado.setor_id)) {
              desabilitado.push(campo);
            }
            break;
          case 16:
            if (this.isEmpty(this.chamado.endereco_id)) {
              desabilitado.push(campo);
            }
            break;
          case 17:
            if (!this.chamado.categoria_secundaria_id) {
              desabilitado.push(campo);
            }
            break;
        }
      }
    });

    if (desabilitado.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getClienteById(id) {
    if (id && typeof (id !== 'object')) {
      this.newSubs = this.clienteService.getById(id).subscribe(
        {
          next: (result) => {
            if (result.cliente) {
              this.getInfos(result.cliente)
              this.getUltimosTickets(result.cliente)

              this.chamado.cliente_id = {
                "id": result.cliente.id,
                "text": result.cliente.nome_fantasia,
                "is_esporadico": result.cliente.is_esporadico,
              }
            }
          }, error: (error) => {
            this.toastr.show(
              error,
              "",
              environment.toastrConfig("danger")
            );
          }
        }
      )
    }
  }

  getInfos(cliente): void {
    if (cliente && cliente.id) {
      this.newSubs = this.chamadosService
        .validaAberturaChamado(cliente.id)
        .subscribe({
          next: (results) => {
            results.limite_contrato = +results.limite_contrato;
            setTimeout(() => {
              this.infosValidacaoCliente = { ...results };
              this.is_retencao = results.is_retencao;
            }, 200);
            if (results.pode_abrir_chamado === false) {
              this.avisoNaoPodeAbrirChamado();
            }
          },
          error: (error) => {
            this.toastr.show(error, "", environment.toastrConfig("danger"));
          }
        });

      this.newSubs = this.clienteContatoService
        .getContatoCliente(cliente.id)
        .subscribe({
          next: (results) => {
            if (results) {
              this.chamado.telefone = results.clienteContato.telefone;
              this.chamado.email_conferencia = results.clienteContato.email;
              this.chamado.nome_contato = [results.clienteContato.descricao];
            } else {
              if(this.chatData && this.chatData.contato) {
                this.chamado.telefone = this.chatData.contato.telefone;
                this.chamado.email_conferencia = this.chatData.contato.email;
                this.chamado.nome_contato = [this.chatData.contato.descricao];
              } else {
                this.chamado.telefone = null;
                this.chamado.email_conferencia = null;
                this.chamado.nome_contato = null;
              }
            }
          },
          error: (error) => {
            this.toastr.show(error, "", environment.toastrConfig("danger"));
          }
        });

      this.newSubs = this.clienteContatoService
        .getListaContatoPadrao(cliente.id)
        .subscribe({
          next: (results) => {
            if(this.chatData && this.chatData.contato) {
              if(results.clienteContato.length) {
                results.clienteContato.forEach((contato) => {
                  this.idContatoPadrao.push(contato)
                });
              }

              setTimeout(() => {
                this.camposCustomizaveis.map((campo) => {
                  if (campo.chave === '@Contatos') {
                    campo.valor = this.idContatoPadrao;
                  }
                });
              }, 1000);
            } else {
              this.idContatoPadrao = results.clienteContato;
              this.camposCustomizaveis.map((campo) => {
                if (campo.chave === '@Contatos') {
                  campo.valor = results.clienteContato;
                }
              });
            }
          },
          error: (error) => {
            this.toastr.show(error, "", environment.toastrConfig("danger"));
          }
        });

      this.newSubs = this.chamadosService
        .ObterInadimplenciaChamado(cliente.id)
        .subscribe({
          next: (results) => {
            this.existe_alerta = results.existe_alerta;
            this.existe_bloqueio = results.existe_bloqueio;
          },
          error: (error) => {
            this.toastr.show(error, "", environment.toastrConfig("danger"));
          }
        });

      this.newSubs = this.clienteEnderecoService.getClienteEnderecoById({ cliente_id: cliente.id, is_ativo: true })
        .subscribe({
          next: (results) => {
            this.business_unit = results.dropdown;
          }, error: (error) => {
            this.toastr.show(error, '', environment.toastrConfig('danger'));
          }
        });
    } else {
      this.chamado.categoria_primaria_id = null;
      this.chamado.categoria_secundaria_id = null;
      this.chamado.endereco_id = null;
      this.business_unit = undefined;
    }
  }

  avisoNaoPodeAbrirChamado(): void {
    swal
      .fire({
        title: this.translate.instant("CLIENTE_NAO_PODE_ABRIR_CHAMADO"),
        html: this.infosValidacaoCliente["mensagem_erro"],
        icon: "error",
        focusConfirm: true,
        showCancelButton: false,
        confirmButtonText: this.translate.instant("FECHAR"),
      })
      .then((result) => {
        this.chamado.cliente_id = {};
        this.infosValidacaoCliente = {
          limite_contrato: 0,
          is_retencao: false,
        };

        this.is_retencao = false;
        this.chamado.telefone = "";
        this.chamado.email_conferencia = "";
        this.chamado.nome_contato = "";
        this.existe_alerta = false;
        this.existe_bloqueio = false;
        this.ultimosChamados = [];
      });
  }

  getUltimosTickets(cliente): void {
    if (cliente && cliente.id) {
      this.newSubs = this.chamadosService
        .obterUltimosChamadosCliente(cliente.id, 4)
        .subscribe(
          (results) => {
            this.ultimosChamados = results.lista;
          },
          (error) => {
            this.toastr.show(error, "", environment.toastrConfig("danger"));
          }
        );
    }
  }

  abrirModalContatos(): void {
    if (this.chamado && this.chamado.cliente_id && this.chamado.cliente_id.id) {
      const modalRef = this.modalService.open(
        ModalListaContatosClienteComponent,
        {
          backdrop: "static",
          scrollable: false,
          windowClass: "modal-custom-milvus",
          keyboard: true,
          size: "medio",
        }
      );

      let _contatosPadrao = [];

      this.camposCustomizaveis.map((campo) => {
        if (campo.chave === '@Contatos') {
          _contatosPadrao = campo.valor;
        }
      })

      let data = {
        cliente: this.chamado.cliente_id,
        contatosSelecionados: _contatosPadrao ? _contatosPadrao : [],
      };
      modalRef.componentInstance.contatoData = data;
      modalRef.result.then((result) => {
        if (result !== undefined) {
          this.chamado.nome_contato = [];
          this.chamado.email_conferencia = [];
          this.idContatoPadrao = [];
          result.forEach((contato) => {
            this.chamado.nome_contato.push(contato.descricao);
            this.chamado.email_conferencia.push(contato.email);
            this.idContatoPadrao.push(contato);
          });
          this.setValuesContatoCamposCustomizaveis();
        }
      });
    } else {
      swal
        .fire({
          title: this.translate.instant("NAO_HA_CLIENTE_SELECIONADO"),
          icon: "info",
          focusConfirm: true,
          showCancelButton: false,
          confirmButtonText: this.translate.instant("FECHAR"),
        })
        .then((result) => { });
    }
  }

  removeContato(contato, index): void {
    this.chamado.nome_contato = [];
    this.chamado.email_conferencia = [];
    this.idContatoPadrao.splice(index, 1);
    this.idContatoPadrao.forEach((item) => {
      this.chamado.nome_contato.push(item.descricao);
      this.chamado.email_conferencia.push(item.email);
    });
    this.setValuesContatoCamposCustomizaveis();
  }

  setValuesContatoCamposCustomizaveis(): void {
    this.camposCustomizaveis.map((campo) => {
      if (campo.chave === '@Contatos') {
        campo.valor = this.idContatoPadrao;
      }
    })
  }

  triggerFileInput(): void {
    let fileInput = document.getElementById("fileInputCriarChamado");
    fileInput.click();
  }

  triggerFileInputCustom(): void {
    let fileInput = document.getElementById("fileInputCriarChamadoCustom");
    fileInput.click();
  }

  changeFile(file): void {
    if (file.length > 0 && file.length < 6) {
      for (let i = 0; i < file.length; i++) {
        var mime = require("mime");
        var tipo = mime.getType(file[0].name);

        if (tipo !== null) {
          tipo = tipo.split("/");
        } else {
          let ext = file[0].name.split(".");
          tipo = ext;
        }

        tipo[1] = environment.convertUploadType(tipo[1]);

        var suportado = this.uploadFileType.indexOf(tipo[1]);
        if (file[i] && file[i].size <= this.uploadFileSize && suportado !== -1) {
          if(this.chamado.file.length < 5) {
            var reader = new FileReader();
            reader.readAsDataURL(file[i]);
            reader.onload = (event) => {
              this.filesSelected.push({
                name: file[i].name,
                type: file[i].type,
                url: this._sanitizer.bypassSecurityTrustResourceUrl(
                  event.target.result.toString()
                ),
              });
            };
            this.chamado.file.push(file[i]);
          } else {
            this.toastr.show(this.translate.instant('QNT_ANEXOS_EXCEDIDO'), '', environment.toastrConfig('danger'));
          }
        } else {
          this.toastr.show(
            this.translate.instant("ARQUIVO_NAO_SUPORTADO_OU_EXCEDEU_LIMITE"),
            "",
            environment.toastrConfig("danger")
          );
        }
      }
    } else if(file.length >= 6) {
      this.toastr.show(this.translate.instant('QNT_ANEXOS_EXCEDIDO'), '', environment.toastrConfig('danger'));
    }
  }

  changeFileCustom(file): void {
    if (file.length > 0 && file.length < 6) {
      for (let i = 0; i < file.length; i++) {
        var mime = require("mime");
        var tipo = mime.getType(file[i].name);

        if (tipo !== null) {
          tipo = tipo.split("/");
        } else {
          let ext = file[i].name.split(".");
          tipo = ext;
        }

        tipo[1] = environment.convertUploadType(tipo[1]);

        var suportado = this.uploadFileType.indexOf(tipo[1]);
        if (file[i] && file[i].size <= this.uploadFileSize && suportado !== -1) {
          if(this.chamado.file.length < 5) {
            var reader = new FileReader();
            reader.readAsDataURL(file[i]);
            reader.onload = (event) => {
              this.filesSelectedCustom.push({
                name: file[i].name,
                type: file[i].type,
                url: this._sanitizer.bypassSecurityTrustResourceUrl(
                  event.target.result.toString()
                ),
              });
            };
            this.chamado.file.push(file[i]);
          } else {
            this.toastr.show(this.translate.instant('QNT_ANEXOS_EXCEDIDO'), '', environment.toastrConfig('danger'));
          }
        } else {
          this.toastr.show(
            this.translate.instant("ARQUIVO_NAO_SUPORTADO_OU_EXCEDEU_LIMITE"),
            "",
            environment.toastrConfig("danger")
          );
        }
      }
    } else if(file.length >= 6) {
      this.toastr.show(this.translate.instant('QNT_ANEXOS_EXCEDIDO'), '', environment.toastrConfig('danger'));
    }
  }

  excluirAnexo(index): void {
    swal
      .fire({
        title: this.translate.instant("DESEJA_EXCLUIR_ANEXO"),
        icon: "error",
        focusConfirm: true,
        showCancelButton: true,
        confirmButtonText: this.translate.instant("SIM"),
        cancelButtonText: this.translate.instant("NAO"),
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.filesSelected.splice(index, 1);
          this.chamado.file.splice(index, 1);
        }
      });
  }

  excluirAnexoCustom(index): void {
    swal
      .fire({
        title: this.translate.instant("DESEJA_EXCLUIR_ANEXO"),
        icon: "error",
        focusConfirm: true,
        showCancelButton: true,
        confirmButtonText: this.translate.instant("SIM"),
        cancelButtonText: this.translate.instant("NAO"),
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.filesSelectedCustom.splice(index, 1);
          this.chamado.file.splice(index, 1);
        }
      });
  }

  keyPressCode(event) {
    return event.keyCode >= 48 && event.keyCode <= 57;
  }

  checkFieldsDefault(field_required, text): boolean {
    if (field_required) {
      return text !== '';
    } else {
      return true;
    }
  }

  validaSalvar(): boolean {
    if (
      this.chamado.cliente_id &&
      this.checkFieldsDefault(this.required_default.assunto, this.chamado.assunto) &&
      this.checkFieldsDefault(this.required_default.descricao, this.verifyDescricao()) &&
      (this.chamado.data_criacao !== undefined ||
        this.chamado.data_criacao !== null ||
        this.chamado.data_criacao !== "")
    ) {
      if (this.existe_bloqueio === true) {
        this.toastr.show(
          this.translate.instant("CLIENTE_COM_BLOQUEIO"),
          "",
          environment.toastrConfig("danger")
        );
        return true;
      } else if (this.infosValidacaoCliente["pode_abrir_chamado"] === false) {
        this.toastr.show(
          this.translate.instant("CLIENTE_COM_BLOQUEIO"),
          "",
          environment.toastrConfig("danger")
        );
        return true;
      } else {
        var campos_obrigatorios = [];
        this.camposCustomizaveis.forEach((campo) => {
          if (campo.is_obrigatorio === true) {
            campos_obrigatorios.push(campo);
          }
        });

        if (campos_obrigatorios.length > 0) {
          var campos_faltantes = [];
          campos_obrigatorios.forEach((campo) => {
            if(campo.valor && typeof(campo.valor) === 'object') {
              if (Object.keys(campo.valor).length === 0) {
                campos_faltantes.push(campo);
              }
            } else if(!campo.valor) {
              campos_faltantes.push(campo);
            }
          });

          if (campos_faltantes.length > 0) {
            this.toastr.show(
              this.translate.instant("PREENCHA_CAMPOS_OBRIGATORIOS", {
                campos: campos_faltantes.map((element) => { return element.label }).toString(),
              }),
              "",
              environment.toastrConfig("danger")
            );
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      this.toastr.show(
        this.translate.instant("PREENCHA_CAMPOS_OBRIGATORIOS_PADRAO"),
        "",
        environment.toastrConfig("danger")
      );
      return true;
    }
  }

  validaSalvarCamposCustomizaveis(): boolean {
    var campos_obrigatorios = [];
    var campos_incorretos = [];
    this.camposCustomizaveis.forEach((campo) => {
      if (campo.is_obrigatorio === true) {
        if (campo.valor === "" || (campo.valor && campo.valor.length === 0)) {
          campos_obrigatorios.push(campo);
        }
      }

      if (campo.valor !== "" && campo.is_check === false && campo.tipo_campo_id > 9) {
        campos_incorretos.push(campo);
      }
    });

    if (campos_obrigatorios.length > 0) {
      this.toastr.show(
        this.translate.instant("PREENCHA_CAMPOS_OBRIGATORIOS", {
          campos: campos_obrigatorios.map((element) => { return element.label }).toString(),
        }),
        "",
        environment.toastrConfig("danger")
      );
      return true;
    } else if (campos_incorretos.length > 0) {
      this.toastr.show(
        this.translate.instant("PREENCHA_CAMPOS_CORRETAMENTE"),
        "",
        environment.toastrConfig("danger")
      );
      return true;
    } else {
      return false;
    }
  }

  verificaRequeridoCampoNormal(value): boolean {
    if (value === undefined || value === null || value === "") {
      return true;
    } else {
      return false;
    }
  }

  verificaRequerido(id): boolean {
    var requerido;
    this.camposCustomizaveis.forEach((campo) => {
      if (campo.id === id) {
        if (campo.is_obrigatorio !== null) {
          requerido = campo.campoCustom.is_obrigatorio;
        } else {
          requerido = false;
        }
      }
    });

    return requerido;
  }

  verificaContatoRequerido(id): boolean {
    if (this.verificaRequerido(id)) {
      if (
        this.chamado &&
        this.chamado.nome_contato &&
        this.chamado.nome_contato.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getPrioridade(categoria_secundaria): void {
    if (categoria_secundaria) {
      this.newSubs = this.categoriasService
        .getPrioridadeCategoriaSecundaria(categoria_secundaria.id)
        .subscribe(
          (results) => {
            this.chamado.prioridade = results.prioridade;
          },
          (error) => {
            this.toastr.show(error, "", environment.toastrConfig("danger"));
          }
        );
    }
  }

  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (+max + 1 - +min)) + +min;
  }

  abrirModalLocalizacaoTecnico(chamado): void {
    if (!chamado.usuario_tecnico_id) {
      swal
        .fire({
          title: this.translate.instant("OPERADOR_NAO_SELECIONADO"),
          icon: "info",
          focusConfirm: true,
          showCancelButton: false,
          confirmButtonText: this.translate.instant("FECHAR"),
        })
        .then((result) => { });
    } else if (!chamado.cliente_id.id) {
      swal
        .fire({
          title: this.translate.instant("CLIENTE_NAO_SELECIONADO"),
          icon: "info",
          focusConfirm: true,
          showCancelButton: false,
          confirmButtonText: this.translate.instant("FECHAR"),
        })
        .then((result) => { });
    } else {
      const modalRef = this.modalService.open(ModalMapaOperadorComponent, {
        backdrop: "static",
        scrollable: false,
        windowClass: "modal-custom-milvus",
        keyboard: true,
        size: "grande",
      });
      let data = {
        cliente: chamado.cliente_id.id,
      };
      modalRef.componentInstance.mapaData = data;
      modalRef.result.then((result) => { });
    }
  }

  verificaRequeridoCustomizaveis(campo): boolean {
    if (campo.is_obrigatorio) {
      if (campo.valor !== "" && campo.valor !== null && campo.valor !== undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  abrirModalInfosCliente(cliente_id): void {
    const modalRef = this.modalService.open(ModalInfosClienteChamadoComponent, {
      backdrop: "static",
      windowClass: "modal-custom-milvus",
      keyboard: true,
      size: "medio",
      scrollable: false,
    });

    let data = cliente_id;

    modalRef.componentInstance.clienteData = data;
    modalRef.result.then((result) => { });
  }

  setValuesCustomFieldsCategories(): void {
    this.camposCustomizaveis.map((campo) => {
      if (campo.chave === '@Categorias') {
        campo.valor = this.chamado.categoria_primaria_id;
      }
      if (campo.chave === '@SubCategoria') {
        campo.valor = this.chamado.categoria_secundaria_id;
      }
    })
  }

  changeCategory(categories): void {
    if (categories) {
      if (categories.primary) {
        this.chamado.categoria_primaria_id = Number(categories.primary);
        this.chamado.categoria_secundaria_id = categories.id;
        let _payloadToData = {
          categoria_primaria_id: this.chamado.categoria_primaria_id,
          categoria_secundaria_id: this.chamado.categoria_secundaria_id
        };
        this.getDataFromCategory(_payloadToData);
      } else {
        this.chamado.categoria_primaria_id = categories.id;
        this.chamado.categoria_secundaria_id = null;
        let _payloadToData = {
          categoria_primaria_id: this.chamado.categoria_primaria_id
        };
        this.getDataFromCategory(_payloadToData);
      }
    } else {
      this.chamado.categoria_primaria_id = null;
      this.chamado.categoria_secundaria_id = null;
      this.block_desk = false;
      this.chamado.mesa_trabalho_id = this.default_desk;
      this.validacaoGatilho();
    }

    this.setValuesCustomFieldsCategories();
  }

  getDataFromCategory(payload: any): void {
    this.newSubs = this.categoryCatalogueService.getDataConfigFromCategory(payload)
      .subscribe({
        next: (results) => {
          if (results.mesa.id) {
            this.chamado.mesa_trabalho_id = { ...results.mesa, ...{ text: '' } };
            if (results.mesa.id !== results.mesa_padrao.id) {
              this.block_desk = true;
            } else {
              this.block_desk = false;
              this.chamado.mesa_trabalho_id.text = results.mesa_padrao && results.mesa_padrao.text ? results.mesa_padrao.text : '';
            }
          } else {
            this.chamado.mesa_trabalho_id = results.mesa_padrao;
            this.block_desk = false;
          }
          this.default_desk = results.mesa_padrao;

          if (results.prioridade) {
            this.chamado.prioridade = results.prioridade;
          }

          this.camposCustomizaveis.map(
            (campo) => {
              if (campo.chave === '@MesaTrabalho') {
                campo.valor = this.chamado.mesa_trabalho_id && this.chamado.mesa_trabalho_id.id ? this.chamado.mesa_trabalho_id.id : null;
              }
              if (campo.chave === '@PrioridadeChamado') {
                campo.valor = this.chamado.prioridade && this.chamado.prioridade.id ? this.chamado.prioridade.id : null;
              }
            }
          );

          setTimeout(() => {
            this.validacaoGatilho();
          }, 500);
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error_msg, '', environment.toastrConfig('danger'));
        }
      });
  }

  checkValue(input: string, tipo_campo_id: number, campo): void {
    const payload = {
      valor: input,
      tipo_campo_id: tipo_campo_id,
      campo: campo
    }
    
    this.validacaoEspecifica(input, tipo_campo_id, campo);
  }

  onCampoCustomizadoChange(campo): void {
    this.preenchendoCampoCustom = true;
    this.checkFieldSubject$.next(campo);
  }

  validacaoEspecifica(valor, tipo_campo_id, campo): void {
    if (valor !== '') {
      const payload = {
        valor: valor,
        tipo_campo_id: tipo_campo_id
      }
      this.newSubs = this.campoCustomizavelService.validacaoEspecifica(payload).subscribe(
        {
          next: (result) => {
            if (result.resultado === false) {
              this.toastr.show(result.error_msg, '', environment.toastrConfig('danger'));
            }
            this.preenchendoCampoCustom = false;
            campo.is_check = result.resultado;
            campo.valor = valor;
            this.campo_personalizado_preenchido_incorretamente = !result.resultado;
            this.validacaoGatilho();
          }, error: (error) => {
            this.preenchendoCampoCustom = false;
            console.error(error);
            this.toastr.show(error, '', environment.toastrConfig('danger'));
          }
        }
      )
    } else {
      this.preenchendoCampoCustom = false;
      campo.is_check = true;
      campo.valor = valor;
      this.campo_personalizado_preenchido_incorretamente = false;
    }
  }

  verifyAssunto(): boolean {
    if (this.required_default.assunto) {
      return this.chamado.assunto === '' || this.chamado.assunto === undefined || this.chamado.assunto === null;
    } else {
      return false;
    }
  }

  verifyDescricao(): boolean {
    if (this.required_default.descricao) {
      let description_value = (this.newTicketForm.value.description.text) ? this.newTicketForm.value.description.text.replaceAll('\n', '') : '';
      return description_value === '' || description_value.length < 6;
    } else {
      return false;
    }
  }

  verifyAccomplished(): boolean {
    let accomplished_value = (this.newTicketForm.value.accomplished.text) ? this.newTicketForm.value.accomplished.text.replaceAll('\n', '') : '';
    return accomplished_value === '' || accomplished_value.length < 6;
  }

  verifyChaveDontShow(chave): boolean {
    let _keys = ['@Slas', '@Progresso', '@SubCategoria', '@SituacaoContrato', '@UltimosTickets', '@QuemViu', '@Seguidores'];

    return !_keys.includes(chave);
  }

  deleteCampoCustom(index): void {
    this.camposCustomizaveis[index].valor = null;
  }

  formatValuesToArray(array): void {
    return array ? array.map(item => item.id) : null;
  }

  setArrayInField(campoCustom, event): void {
    campoCustom.valor = event && event.length > 0 ? event : null;
  }

  setValuesPrioridade(event, campoCustom): void {
    if (event) {
      this.chamado.prioridade = event;
      campoCustom.valor = event.id;
    } else {
      this.chamado.prioridade = null;
      campoCustom.valor = null;
    }
  }

  setValuesUnidadeDeNegocio(event, campoCustom): void {
    if (event) {
      this.chamado.endereco_id = event;
      campoCustom.valor = event;
    } else {
      this.chamado.endereco_id = null;
      campoCustom.valor = null;
    }
  }

  setValuesMesaTrabalho(event, campoCustom): void {
    if (event) {
      this.chamado.mesa_trabalho_id = event;
      campoCustom.valor = event.id;
    } else {
      this.chamado.mesa_trabalho_id = null;
      campoCustom.valor = null;
    }
  }

  setValuesTipoChamado(event, campoCustom): void {
    if (event) {
      this.chamado.tipo_chamado_id = event;
      campoCustom.valor = event.id;
    } else {
      this.chamado.tipo_chamado_id = null;
      campoCustom.valor = null;
    }
  }

  setValuesSetor(event, campoCustom): void {
    if (event) {
      this.chamado.setor_id = event;
      campoCustom.valor = event.id;
    } else {
      this.chamado.setor_id = null;
      campoCustom.valor = null;
    }
  }

  setValuesTecnico(event, campoCustom): void {
    if (event) {
      this.chamado.usuario_tecnico_id = event;
      campoCustom.valor = event.id;
    } else {
      this.chamado.usuario_tecnico_id = null;
      campoCustom.valor = null;
    }
  }

  setValuesDispositivo(event, campoCustom): void {
    if (event) {
      this.chamado.dispositivo_vinculado = event;
      campoCustom.valor = event.id;
    } else {
      this.chamado.dispositivo_vinculado = null;
      campoCustom.valor = null;
    }
  }

  generateIdCampoCustom(campoCustom): string {
    let attr = campoCustom.label.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase();
    return `${attr}-${campoCustom.id}`;
  }

  removerTagsHTML(input: string): string {
    return input.replace(/<[^>]*>/g, '');
  }

  setCampoPersonalizadoValidacaoGatilho(): Array<any> {
    let campos_custom = [];
    this.camposCustomizaveis.forEach((campo) => {
      if (campo.is_visivel && campo.tipo_campo_id !== 0 && ((campo.atributo && campo.atributo.includes('personalizado')) || (campo.chave && campo.chave.includes('personalizado')))) {
        campos_custom.push({
          id: campo.id,
          tipo_campo_id: campo.tipo_campo_id,
          valor: this.adjustmentValuesCustomFields(campo, 'id'),
          is_check: campo.is_check,
        });
      }
    });
    
    return campos_custom;
  }

  setValuesCategories(campo, found): void {
    this.categoriesReceived = this.chamado.categoria_secundaria_id ? this.chamado.categoria_secundaria_id : this.chamado.categoria_primaria_id;

    if (campo.chave === '@Categorias' || campo.chave === '@CategoriaPrimaria') {
      this.required_category.primary = found && found.hasOwnProperty('is_obrigatorio') ? found.is_obrigatorio : this.required_category.primary;
    }

    if (campo.chave === '@SubCategoria' || campo.chave === '@CategoriaSecundaria') {
      this.required_category.secondary = found && found.hasOwnProperty('is_obrigatorio') ? found.is_obrigatorio : this.required_category.secondary;
    }
  }

  checkIfKeyIsCustomFieldSelect(chave: string): boolean {
    return chave && chave.includes('personalizado');
  }

  formatIfValueIsBooleanType(campo, found): void {
    if (campo.tipo_campo_id === 5 && (campo.valor && typeof campo.valor === 'number')) {
      found.valor = this.enumBoolean[campo.valor];
    }
  }

  adjustmentValuesCustomFields(campo, atributo): any {
    let _valueAux = null;
    if (campo.valor) {
      if (campo.tipo_campo_id === 5 && campo.valor.hasOwnProperty('value')) {
        _valueAux = campo.valor[atributo];
      } else if (campo.tipo_campo_id === 7 && (campo.valor instanceof Array)) {
        _valueAux = campo.valor.map(String).join(';');
      } else {
        _valueAux = campo.valor;
      }
    }

    return _valueAux;
  }

  adjustmentCustomFieldsList(_chamado): void {
    //CUSTOMIZAVEIS
    if (this.camposCustomizaveis.length > 0) {
      var campos_custom = [];
      this.camposCustomizaveis.forEach((campo) => {
        if (campo.is_visivel && campo.tipo_campo_id !== 0 && ((campo.atributo && campo.atributo.includes('personalizado')) || (campo.chave && campo.chave.includes('personalizado'))) && campo.valor !== null && campo.valor !== '') {
          campos_custom.push({
            id: campo.id,
            tipo_campo_id: campo.tipo_campo_id,
            valor: this.adjustmentValuesCustomFields(campo, 'value'),
            is_check: true
          });
        }
      });

      if (campos_custom.length > 0) {
        _chamado.campos_personalizados = campos_custom;
      }
    }
  }

  setChamadoValidacaoGatilho(): any {
    let aux = this.chamado;
    var _chamado = Object.assign({}, aux);
    _chamado.dispositivo_id = _chamado.dispositivo_vinculado && _chamado.dispositivo_vinculado.id ? _chamado.dispositivo_vinculado.id : null;
    _chamado.prioridade_id = _chamado.prioridade && _chamado.prioridade.id ? _chamado.prioridade.id : null;
    _chamado.endereco_id = _chamado.endereco_id && _chamado.endereco_id.id ? _chamado.endereco_id.id : null;
    _chamado.descricao = this.removerTagsHTML(_chamado.descricao);
    _chamado.campos_personalizados = this.setCampoPersonalizadoValidacaoGatilho();

    for (const chave in _chamado) {
      if (chave !== 'endereco' && _chamado[chave] && _chamado[chave].hasOwnProperty('id')) {
        _chamado[chave] = _chamado[chave]['id'];
      }
    }

    return _chamado;
  }

  buildPayloadValidacaoGatilho(): any {
    return {
      tipo_obrigatoriedade_id: 2, // interno (portal gestor): 2 | externo (portal cliente e client): 1
      chamado: this.setChamadoValidacaoGatilho(),
      config_formulario: this.camposCustomizaveisInicial
    }
  }

  setValuesCamposDefault(): void {
    this.camposCustomizaveis.map((campo) => {
      if (campo.chave === '@EnderecoId') {
        campo.valor = this.chamado.endereco_id;
      }

      if (campo.chave === '@PrioridadeChamado') {
        campo.valor = this.chamado.prioridade && this.chamado.prioridade.id ? this.chamado.prioridade.id : null;
      }

      if (campo.chave === '@Dispositivo') {
        campo.valor = this.chamado.dispositivo_vinculado && this.chamado.dispositivo_vinculado.id ? this.chamado.dispositivo_vinculado.id : null;
      }

      if (campo.chave === '@TipoChamado') {
        campo.valor = this.chamado.tipo_chamado_id;
      }

      if (campo.chave === '@MesaTrabalho') {
        campo.valor = this.chamado.mesa_trabalho_id;
      }

      if (campo.chave === '@Tecnico') {
        campo.valor = this.chamado.usuario_tecnico_id;
      }

      if (campo.chave === '@Setor') {
        campo.valor = this.chamado.setor_id;
      }

      if (campo.chave === '@Categorias') {
        campo.valor = this.categoriesReceived ? this.categoriesReceived : null;
      }

      if (campo.chave === '@Contatos') {
        campo.valor = this.idContatoPadrao ? this.idContatoPadrao : null;
      }
    })
  }

  validacaoGatilho(): void {
    let payload = this.buildPayloadValidacaoGatilho();

    this.newSubs = this.campoCustomizavelService.validacaoGatilho(payload).subscribe(
      {
        next: (result) => {
          let camposCustomizaveis = result.filter((element) => element.is_visivel === true);

          this.camposCustomizaveis.map((campo) => {
            let found = camposCustomizaveis.find((element) => element.id === campo.id);

            if (campo.chave === '@Categorias' || campo.chave === '@CategoriaPrimaria' ||
              campo.chave === '@SubCategoria' || campo.chave === '@CategoriaSecundaria') {
              this.setValuesCategories(campo, found);
            }

            if (found) {
              found.valor = campo.valor;
              this.formatIfValueIsBooleanType(campo, found);
            }

          })
          this.camposCustomizaveis = camposCustomizaveis;
          this.setValuesCamposDefault();
        },
        error: (error) => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          console.error(error);
        }
      }
    )
  }

  adicionaContato(valor): void {
    if(valor) {
      this.novoContatoAdd = false;
      this.newSubs = this.clienteContatoService.getClienteContatoById(valor.id)
        .subscribe({
          next: (results) => {
            if(results.clienteContato) {
              let _contatosPadrao = [];
  
              this.camposCustomizaveis.map((campo) => {
                if (campo.chave === '@Contatos') {
                  _contatosPadrao = campo.valor;
                }
              });

              setTimeout(() => {
                if(!_contatosPadrao.find((contato) => contato.id === results.clienteContato.id)) {
                  _contatosPadrao.push(results.clienteContato);
                  this.chamado.nome_contato = [];
                  this.chamado.email_conferencia = [];
                  this.idContatoPadrao = [];
                  _contatosPadrao.forEach((contato) => {
                    this.chamado.nome_contato.push(contato.descricao);
                    this.chamado.email_conferencia.push(contato.email);
                    this.idContatoPadrao.push(contato);
                  });
                  this.setValuesContatoCamposCustomizaveis();
                  this.novoContatoAdd = true;
                } else {
                  this.toastr.show(this.translate.instant('CONTATO_JA_EXISTE'), '', environment.toastrConfig('warning'));
                  this.novoContatoAdd = true;
                }
              }, 500);
            }
          }, error: (error) => {
            console.error(error);
          }
        });
    }
  }
}
