<nav class="navbar navbar-top navbar-expand navbar-dark bg-white border-bottom fixed-top" id="navbar-main" *ngIf="!modo_tv" style="zoom: 0.85">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Search form -->
      <!-- RETIRADO PARA FUTURA IMPLEMENTAÇÃO -->
      <form *ngIf="permissao.VisualizarPesquisaGlobal"
        class="navbar-search navbar-search-light form-inline mr-sm-3"
        id="navbar-search-main" [ngClass]="{'navbar-search-sidenav-closed': verifySidenavStatus() === false, 'navbar-search-sidenav-opened': verifySidenavStatus()}"
      >
        <div class="form-group mb-0" [ngClass]="{ focused: focus === true }">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search search-icon" (click)="search()"></i>
              </span>
            </div>
            <input
              class="form-control input-menu-search menu-search-global"
              placeholder="{{ 'BUSCAR' | translate }}"
              type="text"
              (focus)="focus = true"
              (blur)="focus = false"
              style="height: 34px;"
              [formControl]="globalSearchControl"
              (keydown.enter)="search()"
            />
          </div>
        </div>
        <button
          type="button"
          class="close"
          (click)="closeSearch()"
          data-action="search-close"
          data-target="#navbar-search-main"
          aria-label="Close"
        >
          <i class="mdi mdi-close"></i>
        </button>
      </form>
      <!-- Navbar links -->
      <div 
        class="navbar-nav align-items-center col d-flex justify-content-center" 
        style="padding-left: 12px;">
        <div *ngIf="status_empresa_id !== undefined" class="nav-item">
          <div *ngIf="status_empresa_id === '3' || status_empresa_id === '2'" class="alert alert-warning" style="text-align: center;  top: 7px; padding-top: 8px;">
            {{'AVISO_EMPRESA_BLOQUEIO' | translate}} {{data_bloqueio_conta | date: 'dd/MM/yyyy' + '.'}}
          </div>
          <div *ngIf="status_empresa_id === '4'" class="alert alert-warning" style="text-align: center;  top: 7px; padding-top: 8px;">
            {{'AVISO_ANUAL_EMPRESA_BLOQUEIO' | translate}}
          </div>
        </div>
      </div>
      <div class="col-4 alert-wrapper">
        <div *ngFor="let alert of avisos">
          <alert type="danger" [dismissible]="true" (onClosed)="onClosed(alert)">{{ alert.descricao }}</alert>
        </div>
      </div>
      <div class="navbar-nav align-items-center ml-md-auto">
        <div *ngIf="usuario && usuario.empresa && usuario.empresa.is_trial" class="nav-item mr-3 d-flex align-items-center" placement="bottom-right">
          <span class="mr-2">
            {{'FALTAM_DIAS_ACABAR_TRIAL' | translate:{dias: usuario.empresa.tempo_restante_trial} }}
          </span>
          <button class="btn btn-danger btn-md badge-pill" (click)="redirectAssinarPlano()">
            <span [translate]="'QUERO_ASSINAR'"></span>
          </button>
        </div>
        <div *ngIf="visualizaConfigFiltros === true"  class="nav-item mr-3">
          <button class="btn btn-md btn-darkpurple badge-pill" (click)="abrirModalConfigFiltros()">
            <span class="badge-new" [translate]="'NOVO'"></span>
            <i class="mdi mdi-filter"></i>
            <span [translate]="'FILTROS'"></span>
          </button>
        </div>
        <ng-container *ngIf="tipoPerfilAcesso !== 3">
          <li *ngIf="permissao.VisualizarChamados" class="nav-item mr-3" placement="bottom-right">
            <button class="btn btn-darkblue btn-md badge-pill" (click)="abrirModalNovoTicket()" [disabled]="status_empresa_id === '3'">
              <i class="fas fa-ticket-alt"></i>
              <span [translate]="'NOVO_TICKET'"></span>
            </button>
          </li>
          <div class="nav-item mr-3" placement="bottom-right">
            <button class="btn btn-success btn-md badge-pill" (click)="abrirModalNovaTarefa()" [disabled]="status_empresa_id === '3'">
              <i class="fas fa-list"></i>
              <span [translate]="'NOVA_TAREFA'"></span>
            </button>
          </div>
        </ng-container>
        <li class="nav-item mr-3" placement="bottom-right">
          <button class="btn btn-help btn-md badge-pill" (click)="openChatWidget()" id="toggleButtonChat">
            <img src="assets/img/icons/svg/chat-milvus.svg" alt="chat-milvus" style="height: 15px;">
            <span *ngIf="!chatOpened">&nbsp; {{'AJUDA' | translate}}</span>
            <span *ngIf="chatOpened">&nbsp; {{'FECHAR' | translate}}</span>
          </button>
        </li>
        <li class="nav-item dropdown" dropdown placement="bottom-right" *ngIf="status_empresa_id !== '3'">
          <a class="nav-link dropdown-toggle" href="javascript:void(0)" *ngIf="permissao.VisualizarChat"
            (click)="abrirModalChat()" role="button" dropdownToggle [ngbTooltip]="'Chat'" placement="bottom">
            <i class="fas fa-comment text-dark font-21" [ngClass]="{'alerta-chat': integracao_erro === true}"></i>
            <div class="badge-header-notification" *ngIf="obterIntegracao.nova_mensagem"></div>
          </a>          
        </li>
        <ng-container *ngIf="tipoPerfilAcesso !== 3 && tipoPerfilAcesso !== 2">
          <div class="nav-item dropdown" dropdown placement="bottom-right" *ngIf="status_empresa_id !== '3'" [insideClick]="true">
            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" dropdownToggle  *ngIf="verificaTodasPermissoes()"
             [ngbTooltip]="('NOTIFICACOES' | translate)" placement="bottom">
              <i class="fas fa-bell text-dark font-21" style="transition: all 300ms ease-in-out;"
                [ngClass]="{'bell-drop-open': verificaDropAberto('bell')}"></i>
              <div *ngIf="possuiNotificacoes" class="badge-header-notification" style="margin-left: 11px;"></div>
            </a>
            <div class="dropdown-menu dropdown-menu-lg bg-white dropdown-menu-right" *dropdownMenu id="notificacaoDrop"
              style="width: 420px;">
              <div class="dropdown-header">
                <div class="row">
                  <div class="col-6">
                    <span *ngIf="possuiNotificacoes" class="font-14" style="text-transform: none;">{{'VOCE_POSSUI_NOTIFICACOES' | translate}}</span>
                  </div>
                  <div class="col-6 d-flex justify-content-end">
                    <div class="mr-3" [ngbTooltip]="('INTEGRACOES' | translate)" placement="bottom" style="cursor: pointer;" (click)="listaNotificacoes('integracao')">
                      <img *ngIf="filterNotificationsType === 'integracao'" width="17" src="../../../assets/img/icons/svg/icon_integration_alert.svg">
                      <img *ngIf="filterNotificationsType !== 'integracao'" width="17" src="../../../assets/img/icons/svg/icon_integration_alert_gray.svg">
                      <div *ngIf="possui.integracao" class="badge-header-notification"
                        style="top: -3px;"></div>
                    </div>
                    <div class="mr-3" *ngIf="permissao.VisualizarAlertasServidores" [ngbTooltip]="('SERVIDOR' | translate)" placement="bottom"
                      [ngClass]="{'origin-alert-selected': filterNotificationsType === 'servidor'}" (click)="listaNotificacoes('servidor')" style="cursor: pointer;">
                      <i class="font-16 fas fa-server"></i>
                      <div *ngIf="possui.servidor" class="badge-header-notification"
                        style="top: -3px;"></div>
                    </div>
                    <div class="mr-3" *ngIf="permissao.VisualizarAlertasTerminais" [ngbTooltip]="('TERMINAIS_DESK_NOTE' | translate)" placement="bottom"
                      [ngClass]="{'origin-alert-selected': filterNotificationsType === 'desktop'}" (click)="listaNotificacoes('desktop')" style="cursor: pointer;">
                      <i class="font-16 fas fa-desktop"></i>
                      <div *ngIf="possui.terminal" class="badge-header-notification"
                        style="top: -3px;"></div>
                    </div>
                    <div class="mr-3" *ngIf="permissao.VisualizarAlertasMobile" [ngbTooltip]="'Mobile'" placement="bottom"
                      [ngClass]="{'origin-alert-selected': filterNotificationsType === 'mobile'}" (click)="listaNotificacoes('mobile')" style="cursor: pointer;">
                      <i class="font-16 fas fa-mobile-alt"></i>
                      <div *ngIf="possui.mobile" class="badge-header-notification"
                        style="top: -3px; margin-left: 6px;"></div>
                    </div>
                    <div class="mr-3" *ngIf="verificaTodasPermissoes()">
                      <a href="javascript:void(0)" class="notificacao-more-button my-2"
                        (click)="marcaTodosComoLido(); $event.stopPropagation()" [ngbTooltip]="translate.instant('MARCAR_TODOS_LIDO')">
                        <i class="fas fa-check"></i>
                    </a>
                    </div>
                  </div>
                </div>              
                <div class="dropdown-divider" *ngIf="verificaTodasPermissoes()"></div>
                <div class="dropdown-notificacoes-body"  *ngIf="verificaTodasPermissoes()">
                  <div *ngFor="let notificacao of notificacoesLista | slice:0:4; let i = index">
                    <div class="row" style="height: 82px; align-items: center;">
                      <div class="col-2">
                        <span class="icon-notificacao rounded-circle">
                          <i *ngIf="notificacao.origem === 'Terminal'" class="font-21 fas fa-desktop"></i>
                          <i *ngIf="notificacao.origem === 'Servidor'" class="font-21 fas fa-server"></i>
                          <i *ngIf="notificacao.origem === 'Mobile'" class="font-21 fas fa-mobile-alt"></i>
                          <img *ngIf="notificacao.origem === 'Integrações'" width="21" src="../../../assets/img/icons/svg/icon_integration_alert.svg">
                        </span>
                      </div>
                      <div class="col-6" style="display: grid;">
                        <span class="notificacao-titulo">{{notificacao.titulo | ellipsis: 25}}</span>
                        <span *ngIf="notificacao.alerta_tipo_id !== 20; else alertaAcronis" class="notificacao-origem">{{notificacao.dispositivo_origem | ellipsis: 25}}</span>
                        <ng-template #alertaAcronis>
                          <span class="notificacao-origem">Acronis</span>
                        </ng-template>
                        <span class="col-6 px-0" [ngbTooltip]="setTooltipDataAteOMomento(notificacao.data_alerta)" placement="right">{{notificacao.data_ate_momento | ellipsis: 25}}</span>
                      </div>
                      <div class="col-4 text-right">
                        <button *ngIf="permissao.VisualizarTickets && !notificacao.chamado_id" type="button"
                          class="btn btn-outline-darkblue btn-sm" [translate]="'NOVO_TICKET'"
                          (click)="abrirModalNovoTicket(notificacao)"></button>
                        <div *ngIf="notificacao.chamado_id" class="outline-chamado-div">#{{notificacao.chamado_id}}</div>
                      </div>
                    </div>
                    <div class="dropdown-divider"></div>
                  </div>
                </div>
                <div *ngIf="verificaTodasPermissoes()" class="text-center">
                  <a href="javascript:void(0)" class="notificacao-more-button"
                    (click)="notificacoesSemPaginacao();">{{'VER_MAIS' | translate}}</a>
                </div>
                <!-- <div *ngIf="mostraTodasNotificacoes" class="text-center mt-4">
                  <a href="javascript:void(0)" class="notificacao-more-button"
                    (click)="notificacoesSemPaginacao(); $event.stopPropagation()">{{'VER_MENOS' | translate}}</a>
                </div> -->
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- User -->
      <div class="navbar-nav align-items-center ml-auto ml-md-0">
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a class="nav-link pr-0 dropdown-toggle" href="javascript:void()" role="button" dropdownToggle>
            <div class="media align-items-center">
              <span class="avatar avatar-md rounded-circle">
                <img *ngIf="usuario.foto !== null" alt="Image placeholder" [src]="usuario.foto" />
                <span *ngIf="usuario.foto === null"
                  style="font-weight: bold; text-transform: uppercase;">{{splitName(usuario.nome)}}</span>
              </span>
              <div class="badge-user-status"
                [ngClass]="{'badge-user-status-online': usuario.is_online_chat, 'badge-user-status-busy': !usuario.is_online_chat}">
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right" *dropdownMenu>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">{{'BEM_VINDO' | translate}} {{usuario.nome}}!</h6>
            </div>
            <div class="dropdown-item" *ngIf="status_empresa_id !== '3'">
              <div class="row">
                <div class="col-6" style="display: grid">
                  <label class="font-12">Chat Milvus</label>
                  <label class="custom-toggle dropdown-item" (click)="$event.stopPropagation()">
                    <input class="custom-toggle-input" type="checkbox" 
                      [checked]="usuario.is_online_chat" (change)="checkIsOnline($event.target.checked)">
                    <span class="custom-toggle-slider rounded-circle" data-label-off="Off" data-label-on="On"></span>
                  </label>
                </div>
                <div class="col-6" style="display: grid">
                  <label class="font-12" [translate]="'ALERTA_CHAT'"></label>
                  <label class="custom-toggle dropdown-item" (click)="$event.stopPropagation()">
                    <input class="custom-toggle-input" type="checkbox" 
                    [checked]="usuario.alerta_chat" (change)="checkAlertaChat($event.target.checked)">
                    <span class="custom-toggle-slider rounded-circle" data-label-off="Off" data-label-on="On"></span>
                  </label>
                </div>
              </div>
            </div>
            <a routerLinkActive="active" [routerLink]="['/user-area/profile']" class="dropdown-item">
              <i class="ni ni-single-02"></i> <span [translate]="'MEU_PERFIL'"></span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/not-permission']" class="dropdown-item"
              *ngIf="!permissaoVisualizar('@VisualizarEmpresa')">
              <i class="fas fa-building"></i> <span [translate]="'DADOS_EMPRESA'"></span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/company-area/company']" class="dropdown-item"
              *ngIf="permissaoVisualizar('@VisualizarEmpresa')">
              <i class="fas fa-building"></i> <span [translate]="'DADOS_EMPRESA'"></span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/not-permission']" class="dropdown-item"
              *ngIf="!permissaoVisualizar('@VisualizarFatura')">
              <i class="far fa-list-alt"></i> <span [translate]="'MEU_PLANO_FATURA'"></span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/user-area/plans']" class="dropdown-item"
              *ngIf="permissaoVisualizar('@VisualizarFatura')">
              <i class="far fa-list-alt"></i> <span [translate]="'MEU_PLANO_FATURA'"></span>
            </a>
            <a routerLinkActive="active" href="javascript:void(0)" class="dropdown-item">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <i class="far fa-sticky-note" style="font-size: 18px; margin-right: 16px;"></i> 
                  <span [translate]="'TEMA'" style="margin-right: 4px;"></span>
                  <span *ngIf="tema === 'Light'" [translate]="'CLARO'"></span>
                  <span *ngIf="tema === 'Dark'" [translate]="'ESCURO'"></span>
                </div>
                <button type="button" class="btn btn-sm" [ngClass]="{'btn-light': tema === 'Light', 'btn-darkgray': tema === 'Dark'}" (click)="toggleTheme(); $event.stopPropagation()">
                  <span *ngIf="tema === 'Dark'" [translate]="'CLARO'"></span>
                  <span *ngIf="tema === 'Light'" [translate]="'ESCURO'"></span>
                </button>
              </div>
            </a>
            <!-- <a routerLinkActive="active" [routerLink]="" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i> <span [translate]="'CONFIGURACOES'"></span>
            </a>
            <a routerLinkActive="active" [routerLink]="" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i> <span [translate]="'ATIVIDADE'"></span>
            </a>
            <a routerLinkActive="active" [routerLink]="" class="dropdown-item">
              <i class="ni ni-support-16"></i> <span [translate]="'SUPORTE'"></span>
            </a> -->
            <div class="dropdown-divider"></div>
            <a href="javascript:void()" (click)="logout()" class="dropdown-item">
              <i class="ni ni-user-run"></i> <span>Logout</span>
            </a>
          </div>
        </li>
      </div>
    </div>
  </div>
</nav>
<div *ngIf="sidenavOpen === true" class="backdrop d-xl-none" (click)="toggleSidenav()"></div>