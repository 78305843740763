import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TokenService } from 'src/app/core/token/token.service';
import { ClienteContatoService } from 'src/app/services/cliente-contato.service';
import { environment } from 'src/environments/environment';
import { ModalCriarContatoClienteComponent } from '../modal-criar-contato-cliente/modal-criar-contato-cliente.component';

@Component({
  selector: 'app-modal-lista-contatos-cliente',
  templateUrl: './modal-lista-contatos-cliente.component.html',
  styleUrls: ['./modal-lista-contatos-cliente.component.scss']
})
export class ModalListaContatosClienteComponent implements OnInit, OnDestroy {
  @Input() contatoData;
  configPagination = environment.getPaginationDefault();
  pagination;
  listaContatos = [];
  listaSelecionados = [];
  selector: string;
  permissao: any = {
    EditarCliente: this.tokenService.checkPermissions('@EditarCliente'),
    CriarNovoContatoTicket: this.tokenService.checkPermissions('@CriarNovoContatoTicket'),
    EditarContatoTicket: this.tokenService.checkPermissions('@EditarContatoTicket')
  };
  newSubs: Subscription;
  arrayFiltrosModalContatos: Array<any> = []

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private clienteContatoService: ClienteContatoService,
    private modalService: NgbModal,
    private elem: ElementRef,
    private tokenService: TokenService
  ) {
    this.selector = this.elem.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {    
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    if (this.contatoData.cliente) {
      this.listarContatos(this.configPagination, { filtro_body: { cliente_id: this.contatoData.cliente.id } });
    } else {
      this.listarContatos(this.configPagination, {});
    }
    this.verificaSelecionados(this.contatoData.contatosSelecionados);
    this.setFiltroModalContatos();
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  listarContatos(pagination, filtros): void {
    this.newSubs = this.clienteContatoService.getListagemContatos(pagination, filtros)
      .subscribe(results => {
        this.listaContatos = results.lista;        
        this.pagination = results.meta.paginate;
      }, error => {
        console.log(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

  setFiltroModalContatos(): void {
    this.arrayFiltrosModalContatos = [
      { nome: this.translate.instant('NOME'), tipo: 'input', model: 'nome', selecionados: 0, concatena: false, modelValue: '' },
      { nome: 'Email', tipo: 'input', model: 'email', selecionados: 0, concatena: false, modelValue: '' },
      { nome: this.translate.instant('CELULAR'), tipo: 'input', model: 'celular', selecionados: 0, concatena: false, modelValue: '' },
      { nome: this.translate.instant('PADRAO'), tipo: 'dropdown', data: [], model: 'is_padrao', selecionados: 0, concatena: false, modelValue: '' }
    ];

    this.arrayFiltrosModalContatos[3].data = [
      { id: 'true', text: this.translate.instant('SIM') },
      { id: 'false', text: this.translate.instant('NAO') },
    ];
  }

  aplicarFiltros(filtros): void {
    var _filtros = filtros;
    _filtros['cliente_id'] = this.contatoData.cliente.id;

    this.listarContatos(this.configPagination, { filtro_body: _filtros });
  }

  verificaSelecionados(selecionados): void {
    if(selecionados[0] === undefined) {
      return 
    }
    selecionados = Object.values(selecionados)
    selecionados.forEach((contato) => {
      this.listaSelecionados.push(contato)
    })
  }

  abrirModalNovoContato(contato?): void {
    const modalRef = this.modalService.open(ModalCriarContatoClienteComponent,
      {
        backdrop: "static",
        scrollable: false,
        windowClass: 'modal-custom-milvus',
        keyboard: true,
        size: "medio",
      });
    let data = {
      cliente: (this.contatoData.cliente) ? this.contatoData.cliente.id : null,
    };
    if (contato) {
      data['contato'] = contato;
    }
    modalRef.componentInstance.novoContatoData = data;
    modalRef.result.then((result) => {
      if (this.contatoData.cliente) {
        this.listarContatos(this.configPagination, { filtro_body: { cliente_id: this.contatoData.cliente.id } });
      } else {
        this.listarContatos(this.configPagination, {});
      }
    });
  }

  paginarListagem(pagina): void {
    this.configPagination.pagina = pagina;
    if (this.contatoData.cliente) {
      this.listarContatos(this.configPagination, { filtro_body: { cliente_id: this.contatoData.cliente.id } });
    } else {
      this.listarContatos(this.configPagination, {});
    }
  }

  onValueChange(event: any, contato: any) {
    if (event) {
      var index = this.listaSelecionados.findIndex(x => x.id === contato.id)
      if (index === -1) {
        this.listaSelecionados.push(contato)
      }
    } else {
      var index = this.listaSelecionados.findIndex(x => x.id === contato.id)
      if (index !== -1) {
        this.listaSelecionados.splice(index, 1)
      }
    }
  }

  selectAllItens() {
    var _checkboxes = this.elem.nativeElement.querySelectorAll(
      ".custom-control-input"
    );
    var _value = _checkboxes[0].checked;
    _checkboxes.forEach((checkbox) => {
      if (checkbox.checked !== _value) {
        checkbox.checked = _value;
      }
    });

    if (_value) {
      this.listaContatos.forEach(e => {
        var index = this.listaSelecionados.findIndex(x => x.id === e.id)
        if (index === -1) {
          this.listaSelecionados.push(e)
        }
      })
    } else {
      this.listaContatos.forEach(e => {
        var index = this.listaSelecionados.findIndex(x => x.id === e.id)
        if (index !== -1) {
          this.listaSelecionados.splice(index, 1)
        }
      })
    }
  }

  close(): void {
    this.modal.close();
  }

  verificaCheck(contato) {
    var index = this.listaSelecionados.findIndex(x => x.id === contato.id)
    return index !== -1
  }

  confirmar() {
    this.modal.close(this.listaSelecionados);
  }

}
