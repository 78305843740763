<div class="modal-content modal-milvus">
  <div class="modal-header">
    <h6 class="modal-title" id="modal-title-default">
      {{ "CHAMAR_CONTATO_WHATS" | translate }}
    </h6>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    >
      <i class="mdi mdi-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div
        [ngClass]="{'col-7': permissao.EditarCliente, 'col-12': !permissao.EditarCliente}"
        style="border-right: 1px solid rgba(140, 138, 138, 0.5)"
      >
        <h6 class="modal-title">{{ "PESQUISA" | translate }}</h6>
        <div class="row">
          <div class="col-12">
            <div
              class="navbar-search navbar-search-light form-inline my-3 mr-lg-3"
              id="navbar-search-main"
            >
              <div class="form-group mb-0" style="width: 100%">
                <div class="input-group input-group-merge" style="width: 100%">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fas fa-search"></i
                    ></span>
                  </div>
                  <input
                    class="form-control"
                    placeholder="{{ 'NUMERO_CONTATO_EMPRESA' | translate }}"
                    type="text"
                    name="search"
                    [(ngModel)]="searchText"
                    (ngModelChange)="filtrarApi($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive table-listagem">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th scope="col" [translate]="'CONTATO'"></th>
                <th scope="col" [translate]="'EMPRESA'"></th>
                <th scope="col" [translate]="'CELULAR'"></th>
                <th scope="col" [translate]="'TELEFONE'"></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lista">
                <td>{{ item.descricao | ellipsis : 15 }}</td>
                <td>{{ item.cliente_nome | ellipsis : 15 }}</td>
                <td>{{ item.celular | phone : item.codigo_pais | null }}</td>
                <td>{{ item.telefone | phone : item.codigo_pais | null }}</td>
                <td class="text-right">
                  <button
                    type="button"
                    class="btn btn-success badge-pill"
                    (click)="abrirConversa(item)"
                  >
                    <span>{{ "CONVERSAR" | translate }}</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination
          *ngIf="lista.length > 0"
          [meta]="contatosPagination"
          (pageChange)="paginarListagem($event)"
        >
        </pagination>
      </div>
      <div *ngIf="permissao.EditarCliente" class="col-5">
        <h6 class="modal-title">{{ "NOVO_CONTATO" | translate }}</h6>

        <div class="row">
          <div class="col-12 form-group">
            <label>{{ "DDG" | translate }}</label>
            <div>
              <label class="switch">
                <input
                  type="checkbox"
                  [checked]="is_ddg"
                  (change)="checkDdg($event)"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>

          <!-- <div class="col-12 form-group">
                        <div class="col-7 justify-content-between pl-0">
                            <label [translate]="'NUMERO_WHATSAPP'"></label>
                            <input class="form-control" mask="(00) 0 0000-0000 || (00) 0000-0000" type="text" [ngClass]="{'required': telefone === '' }"
                                [(ngModel)]="telefone" (ngModelChange)="has_contato = false" />
                        </div>
                        <div class="col-5">
                            <button class="btn btn-success badge-pill mr-0 ml-3" [disabled]="telefone.length < 10"
                                (click)="verificaTelefone(telefone)">{{'VERIFICAR_NUMERO' | translate}}</button>
                        </div>
                    </div> -->

          <div class="col-4 form-group" *ngIf="!is_ddg">
            <label [translate]="'DDI'"></label>
            <select-padrao
              [entidade]="'selectCodigoDdiPais'"
              [required]="true"
              [returnObj]="true"
              (selectChange)="changeDdi($event)"
            ></select-padrao>
          </div>

          <div
            class="col-8 form-group"
            *ngIf="!is_ddg && render && codigo_pais.valor"
          >
            <label [translate]="'NUMERO_WHATSAPP'"></label>
            <div class="input-group mb-2">
              <input
                class="form-control"
                *ngIf="codigo_pais.valor === '55'"
                type="text"
                [ngClass]="{ required: telefone === '' }"
                [mask]="mascara"
                [(ngModel)]="telefone"
              />
              <input
                class="form-control"
                *ngIf="codigo_pais.valor !== '55'"
                type="text"
                [ngClass]="{ required: telefone === '' }"
                [(ngModel)]="telefone"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-success"
                  [disabled]="validateCountryPhoneLength(telefone) || telefone.length === 0"
                  (click)="verificaTelefone()"
                >
                  {{ "VERIFICAR_NUMERO" | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="col-12 form-group" *ngIf="is_ddg">
            <label>DDG</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="ddifixo">+55</span>
              </div>
              <input
                class="form-control"
                type="text"
                mask="0000 000 0000"
                [ngClass]="{ required: telefone === '' }"
                [(ngModel)]="telefone"
                (ngModelChange)="has_contato = false"
                aria-describedby="ddifixo"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-success"
                  [disabled]="validateCountryPhoneLength(telefone) || telefone.length === 0"
                  (click)="verificaTelefone(telefone)"
                >
                  {{ "VERIFICAR_NUMERO" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label [translate]="'CLIENTE'"></label>
          <select-padrao
            [returnObj]="true"
            [entidade]="'selectClientes'"
            [required]="true"
            (selectChange)="cliente = $event"
          ></select-padrao>
        </div>
        <div class="form-group">
          <label [translate]="'NOME'"></label>
          <input class="form-control" type="text" [(ngModel)]="nome" [ngClass]="{'required': nome.length < 3}" />
        </div>
        <div class="text-right" [ngbTooltip]="!has_contato ? ('NECESSARIO_VERIFICAR_NUMERO' | translate) : null">
          <button
            class="btn btn-success badge-pill"
            [disabled]="valid()"
            (click)="salvarIniciarConversa()"
          >
            {{ "SALVAR_CONTATO_INICIAR_CONVERSA" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
