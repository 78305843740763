<div class="modal-content">
    <div class="modal-header">
        <h6 *ngIf="contatoData.cliente" class="modal-title" id="modal-title-default">{{ 'CONTATOS_DO_CLIENTE' |
            translate}} {{contatoData.cliente.text}}</h6>
        <h6 *ngIf="!contatoData.cliente" class="modal-title" id="modal-title-default">{{ 'SELECIONE_CONTATOS' |
            translate}}</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <filtros [tipoFiltro]="'listagemModalContatos'" [arrayFiltros]="arrayFiltrosModalContatos" (filtrosChange)="aplicarFiltros($event)"></filtros>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class=" table-responsive" *ngIf="listaContatos.length > 0">
                    <table class=" table align-items-center table-flush" *ngIf="listaContatos.length > 0 ">
                        <thead class=" thead-light">
                            <tr>
                                <th>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="checkContatosModal"
                                            (click)="selectAllItens()">
                                        <label class="custom-control-label" for="checkContatosModal"></label>
                                    </div>
                                </th>
                                <th [translate]="'NOME'"></th>
                                <th>Email</th>
                                <th [translate]="'CELULAR'"></th>
                                <th [translate]="'PADRAO'"></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let contato of listaContatos; let i = index">
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input"
                                            id="{{'checkContatosModal-'+i}}" [checked]="verificaCheck(contato)"
                                            (change)="onValueChange($event.target.checked, contato)">
                                        <label class="custom-control-label" for="{{'checkContatosModal-'+i}}"></label>
                                    </div>
                                </td>
                                <td [ngClass]="{'contato-hard-bounce': contato.bounce_id === 2}">
                                    <span
                                        [ngbTooltip]="contato.bounce_id === 2 ? translate.instant('CONTATO_HARD_BOUNCE') : ''">{{contato.descricao}}</span>
                                </td>
                                <td>{{contato.email}}</td>
                                <td>{{contato.celular | phone:contato.codigo_pais}}</td>
                                <td>
                                    <span *ngIf="contato.is_padrao" class="label-success" [translate]="'SIM'"></span>
                                    <span *ngIf="!contato.is_padrao" class="label-danger" [translate]="'NAO'"></span>
                                </td>
                                <td class="text-right">
                                    <span 
                                    ngbTooltip="{{(permissao.EditarCliente ? '' : permissao.EditarContatoTicket ? '' : 'SEM_PERMISSAO_BOTAO') | translate}}" 
                                    placement="left">
                                    <button type="button" class="btn btn-sm btn-success btn-sm-rounded-milvus"
                                        [disabled]="!permissao.EditarCliente ? !permissao.EditarContatoTicket : false"
                                        (click)="abrirModalNovoContato(contato)">
                                        <i class="fas fa-pencil-alt"></i>
                                    </button>

                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="listaContatos.length === 0" class="text-center mt-3">
                    <h2 [translate]="'NAO_HA_CONTATOS_DESTE_CLIENTE'"></h2>
                </div>
            </div>
        </div>
        <pagination *ngIf="listaContatos.length > 0" [meta]="pagination" (pageChange)="paginarListagem($event)">
        </pagination>
    </div>
    <div class="modal-footer">
        <span 
        ngbTooltip="{{(permissao.EditarCliente ? '' : permissao.CriarNovoContatoTicket ? '' : 'SEM_PERMISSAO_BOTAO') | translate}}">
            <button type="button" class="btn btn-info badge-pill" (click)="abrirModalNovoContato()"
                [disabled]="!permissao.EditarCliente ? !permissao.CriarNovoContatoTicket : false"
                [translate]="'NOVO_CONTATO'"></button>

        </span>
        <button *ngIf="listaContatos.length > 0" type="button" class="btn btn-success badge-pill" (click)="confirmar()"
            [translate]="'SELECIONAR'"></button>
    </div>
</div>